export interface ResponseBack {
  code:number
  msg:string
  res:any
}

export class OssConfig {
  secret = '';
  access_key= '';
  endpoint_short= '';
  endpoint= '';
  endpoint_fast_short= '';
  endpoint_fast= '';
  bucket_name= '';
  path=''; // 存放资源的路径
  url=''; // 视频链接
  file_id=''; // 唯一id, 用来当上传文件的名称
}

export class WechatData {
  debug = false; // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  appId = ''; // 必填，公众号的唯一标识
  timestamp= Date.now(); // 必填，生成签名的时间戳
  nonceStr= ''; // 必填，生成签名的随机串
  signature= '';// 必填，签名，见附录1
  jsApiList = ['']; // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
  user_id = 0;
}

// 账户详细信息
export interface UserDetailInfo {
  id: number,
  head_img_url: string,
  name: string,
  sex: number,  // 1: 男, 2: 女
  phone: string,
  birthday: string,
  address: string,
  remark: string,
  source: string,
  referrer_id: string,
  referrer_name: string,
  // 孩子账号
  child_list: {
    id: number,
    head_img_url: string,
    name: string
  }[],
  // 父母账号
  parent_list: {
    id: number,
    head_img_url: string,
    name: string
  }[],
  // 兄弟账号
  siblings_list: {
    id: number,
    head_img_url: string,
    name: string
  }[],
}

export class CheckLoginInfo {
  headimgurl = '';
  name = '';
  phone = '';
  // 该账号是否绑定微信, true已经绑定；亲子账号有可能没有绑定微信
  is_binding_wechat = false;
  is_member = false; // 是否是会员（有会员卡）
  has_new_user_coupon = false; // 是否有新人体验券
  // has_new_user_child_coupon: boolean, //是否有少儿新人体验券
  role_info= {
    /*
    'unregistered': (0, u'未注册'),
    'admin': (1, u'管理员'),
    'teacher': (2, u'教师'),
    'student': (3, u'学员')
     */
    role_id: 0,
    role: '',
    role_mapping: new Map<number, string>(),
    perms: <{
      studio_id: number
      studio_name: string
    }[]> [],
    system_page_list: <string[]>[]
  };
  user_id = 0;
  login = false;
  nickname= '';
}
export interface Player {
  signin_time: string,
  name: string,
  icon: string,
  user_id: number,
  user_type: number, // 1=会员 2=新人体验,3新人体验(需要验大众点评券)
  signin_id: number,
  opt_user_id: number,
  opt_user_name: string,
  card_id: number,
  membership_id: number,
  has_signin: boolean, // 是否已经现场签到
}
export interface Card {
  card_id: number,
  course_id: number,
  spend: number,
  card_name: string,
  card_type_id: number,
}

export class ActivityLive {
  activity_id: number=0;
  course_name: string='';
  classroom_id: number=0;
  course_id: number=0;
  course_grade_id: number=0;
  course_level_id: number=0;
  course_kind_id: number=0;
  course_way: number=1;  // 授课方式 1线下，2直播
  duration: number=0;
  teacher_id: number=0;
  start_time: string='';
}

export class CourseDetail {
  id: number = 0;
  course_id = 0;
  is_bundling = 0;
  course_name = '';
  duration = 0;
  spend = 0;
  desc = '';
  course_level_id = 0;
  course_kind_id = 0;
  course_grade_id = 0;
  course_way = 0;
  activity_list = [];
  card_list = [];
}

export class ActivityDetail {
  id: number=0;
  course_name: string='';
  course_id: number=0;
  course_level_id: number=0;
  course_way: number=1;  // 授课方式 1线下，2直播
  mark_type: number=0;  // 课程标记
  start_time: string='';
  studio_id: number=0;
  duration: number=0;
  spend: number=0;
  desc: string='';
  city: string='';
  zone: string='';
  studio_name: string='';
  is_proxy: number=0; // 是否代课，0否，1是
  is_isolation: number=0; // 是否独立课，0否，1是
  is_bundling: number=0; // 课程组是否需要一次性预约所有，0否，1是
  max_member: number=0;
  min_member: number=0;
  classroom_id: number=0;
  classroom_name: string='';
  teacher_ids: number[]=[];
  teacher_id: number=0;
  teacher_name: string='';
  teacher_icon: string='';
  course_kind_id: number=0;
  activity_index: number=0;
  course_kind_name: string='';
  players: Player[] = [];
  card_list: Card[] = [];
  course_video = {
    video_url: '',
    video_poster: '',
    video_height: 0,
    video_width: 0,
    show_video: 0,  // 是否显示视频，1显示（表示已经转码完成）
  }; // 课程组预告视频
  activity_list: {
    activity_index: number;
    teacher_id: number,
    teacher_ids: number[],
    activity_id: number,
    start_time: string,
    is_proxy: number,
    is_isolation: number,
    audio_url: string,
    classroom_id: number
    // 本次课程视频列表
    video_list: {
      video_id: number,
      video_url: string,
      video_poster: string,
      video_height: number,
      video_width: number,
      is_collect: number,  // 用户是否收藏了这个视频
      collect_id: number,  // 收藏id
      show_video: number,  // 是否显示视频，1显示（表示已经转码完成）
    }[];
  }[] = [];

  live_push_url = '';  // 直播推流链接（老师直播使用）
  live_play_url = '';  // 播放链接1
  live_play_url_2 = ''; // 播放链接1
  live_status = 1; // 直播状态，1 未开播，2正在播，3结束
}
export class SigninInfoModel {
  status: number = 0;  // 0未预约，1已预约(签到)
  activity_id: number = 0; // 课程id
  teaching_view_status: number = 0;  // 能否查看课堂页面 1可以
  signin_num: number = 0;  // 已经预约人数
  // 签到信息
  signin_info = {
    id: 0,
    type: 0,
    membership_id: 0,
    order_id: 0,
  };
}
export class SigninInfo extends SigninInfoModel {
  now_date: string = '';  // 当前时间
}

export interface Teacher {
  dance_type_id: number[],
  user_id: number,
  teacher_id: number,
  is_proxy: number,
  name: string,
  user_avatar:string,
  teacher_avatar:string,
  desc:string,
}
export class CourseLevel {
  type: string='';
  id: number=0;
  is_bundling: number=0;
  notice: string='';
}
export class CourseGrade {
  name: string='';
  id: number=0;
}
export interface DanceType {
  type: string,
  desc: string,
  id: number
}
export interface Studio {
  address: string,
  brand_id: number,
  city: string,
  contact_phone: string,
  desc: string,
  id: number,
  logo_url: string,
  name: string,
  province: string,
  zone: string,
  latitude: string, //纬度
  longitude: string, //经度
  distance: number,// 门店距离当前定位的距离，米
}
export class BasicInfo {
  course_level: CourseLevel[] = [];
  course_grade: CourseGrade[]= [];
  dance_type: DanceType[]= [];
  studio: Studio[]= []; // 所有门店列表
  classroom: { studio_id: number, id: number,capacity: number, name: string }[]= [];
  teacher: Teacher[]= [];
  cancel_hours: number = 0 // 取消课程的时间限制，例如 24 代表课程开始前的24小时前，可以取消预约课
  card: { id: number, name: string, type_id: number }[] = [];
  md5:string = '';
}

export class CommentInfo {
  user_name = ''; // 学员昵称
  content = ''; //  留言内容
  create_time = ''; // 留言创建时间
  comment_id:number = 0; //
  height: number=0;
}
export class CommentNoticeData {
  comment_list:CommentInfo[] = [];
  notice = {
    is_del: 0,
    content: '',
  };
  live_status= 1; //直播状态，1 未开播，2正在播，3结束
}
export class FeedbackContent {
  content = 1;  // 反馈内容， 1可以，2不可以
  num = 0; // 数量
  user_id_list = []
}

export class SigninInfoList {
  status_list: SigninInfoModel[] = [];
  now_date: string = '';  // 当前时间
}

export class ActivityFast {
  activity_id: number=0;
  is_proxy: number=0;
  is_isolation: number=0; // 是否为独立课
  classroom: string='';
  course_name: string='';
  course_way: number=1;  // 授课方式 1线下，2直播
  course_id: number=0;
  duration: number=0;
  spend: number=0;
  desc: string='';
  course_kind_name: string='';
  course_kind_id: number=0;
  course_level_name: string='';
  course_level_id: number=0;
  start_time: string='';
  publish_time: string='';
  studio_name: string='';
  teacher_name: string='';
  has_activity_video: number = 0; // 1有课堂视频
  // 预告视频
  course_video: {
    show_video: number
    video_height: number
    video_url: string
    video_poster: string
    video_width: number
  } =  {
    show_video: 0,
    video_height: 0,
    video_url: '',
    video_poster: '',
    video_width: 0,
  };
  teacher_id: number=0;
  activity_index: number=0;
  max_member: number=0;
  min_member: number=0;
  card_list: Card[]=[];
}
export class StudioResource {
  id = 0;
  url = ''; // 资源链接
  poster_url = ''; // 视频封面
  type = 1;  // 资源类型，1图片，2视频
  for_type = 1;  // 资源类型，1资源是用来导航的，2资源是用来展示门店的
  title = '';
  index = 0;
  create_time = '';
}

// 门店指引数据，图片，视频等
export class StudioGuide {
  id = 0;
  name = '';
  brand_id = 0;
  contact_phone = 0;
  province = 0;
  city = 0;
  zone = 0;
  address = '';
  desc = 0;
  logo_url = '';
  studio_type = 0; // 门店类型，1线下门店，2虚拟门店
  visible_range = 0; // 可见范围:0-对外 1-对内 2-隐藏
  enterprise_id = 0; // 企业id
  resource_list: StudioResource[] = [];
}

export class CommonRedisMark {
  is_exist = 0 ; // 之前不存在这个标记
  data = '1'; // redis 里存的内容
}
