import React from 'react';
import { Modal } from 'antd-mobile';
import './index.scss';
import {ScrollToTopOnMount} from "../../components/top";
import Footer from '../../components/footer/footer';
import {RouteComponentProps} from "react-router";

class VideoData {
  title='';
  videoUrl='';
  poster='';
}

class IntroData {
  title='';
  content='';
  poster=';'
}

interface State {
  videoList1: VideoData[],
  videoList2: VideoData[],
  videoList3: VideoData[],
  introList: IntroData[],
  visible: boolean,
  isWeiXin: boolean,
}
interface Props extends RouteComponentProps {
}
const topImg = require('../../static/img/xiaoniu/top.png');
const logoImg = require('../../static/img/xiaoniu/logo.png');
const aboutGstepsImg = require('../../static/img/xiaoniu/title/title-about-gsteps.png');
const classImg = require('../../static/img/xiaoniu/title/title-class.png');
const miniCodeImg = require('../../static/img/xiaoniu/minicode.png');
const successTitleImg = require('../../static/img/xiaoniu/title/title-success.png');

export class XiaoNiu extends React.PureComponent<Props, State> {  

  constructor(p: any) {
    super(p);
    this.state = {
      videoList1: [
        // {
        //   title: 'Googo',
        //   videoUrl: 'https://oss.gsteps.cn/course_video_after/xiaoniu-googo.mp4',
        //   poster: 'https://oss.gsteps.cn/course_video_after/xiaoniu-googo.jpg',
        // },
        // {
        //   title: '伶子',
        //   videoUrl: 'https://oss.gsteps.cn/course_video_after/xiaoniu-lingzi.mp4',
        //   poster: 'https://oss.gsteps.cn/course_video_after/xiaoniu-lingzi.jpg',
        // },
        // {
        //   title: 'Joy',
        //   videoUrl: 'https://oss.gsteps.cn/course_video_after/xiaoniu-joy.mp4',
        //   poster: 'https://oss.gsteps.cn/course_video_after/xiaoniu-joy.jpg',
        // },
        {
          title: '小余',
          videoUrl: 'https://oss.gsteps.cn/course_video_after/xiaoniu-xiaoyu.mp4',
          poster: 'https://oss.gsteps.cn/course_video_after/xiaoniu-xiaoyu.jpg',
        },
        {
          title: '元元',
          videoUrl: 'https://oss.gsteps.cn/course_video_after/xiaoniu-yuanyuan.mp4',
          poster: 'https://oss.gsteps.cn/course_video_after/xiaoniu-yuanyuan.jpg',
        }
      ],
      videoList2: [
        {
          title: '《How you like that》MvDance 老师联合翻跳',
          videoUrl: 'https://oss.gsteps.cn/course_video_after/activity12159-8aff05fc-31a0-4956-b37e-edf9d66ef589-1594041610571.mp4',
          poster: 'https://oss.gsteps.cn/course_video_after/activity12159-8aff05fc-31a0-4956-b37e-edf9d66ef589-1594041610571.jpg',
        },
        {
          title: '恒通旗舰店 POPPING 常规课《Holding on》',
          videoUrl: 'https://oss.gsteps.cn/course_video_after/activity17167-37915336-afce-44fc-8729-ec7fe2b4392e-1600581251533.mp4',
          poster: 'https://oss.gsteps.cn/course_video_after/activity17167-37915336-afce-44fc-8729-ec7fe2b4392e-1600581251533.jpg',
        },
      ],
      videoList3: [
        {
          title: '新中关店 HIP-HOP 常规课《Make that ass shake》',
          videoUrl: 'https://oss.gsteps.cn/course_video_after/activity13771-787faf1f-f5c3-4b29-b7f2-7812fef46122-1599401890862.mp4',
          poster: 'https://oss.gsteps.cn/course_video_after/activity13771-787faf1f-f5c3-4b29-b7f2-7812fef46122-1599401890862.jpg',
        },
        {
          title: '王然新中关店 JAZZ 常规课《Girls Need Love》',
          videoUrl: 'https://oss.gsteps.cn/course_video_after/activity13727-51cf4109-e576-4c77-a24a-4a02466a5314-1599148753581.mp4',
          poster: 'https://oss.gsteps.cn/course_video_after/activity13727-51cf4109-e576-4c77-a24a-4a02466a5314-1599148753581.jpg',
        },
      ],
      introList: [
        {
          title: '10 家门店',
          content: 'G 社目前在北京各大商圈已开设 10 家直营门店，是实力，更是距离上的便捷。',
          poster: require('../../static/img/xiaoniu/intro01.png'),
        },
        {
          title: '100+ 专业舞蹈老师',
          content: '舞社拥有一批专业的舞蹈老师，均为专业院校出身。',
          poster: require('../../static/img/xiaoniu/intro02.png'),
        },
        {
          title: '专设零基础小班课',
          content: '独创的零基础教学课程，从基本功和舞蹈元素学起，让舞蹈小白更轻松入门。',
          poster: require('../../static/img/xiaoniu/intro03.png'),
        },
      ],
      visible: false,
      isWeiXin: false,
    }
  }

  componentWillMount(): void {
    const ua = window.navigator.userAgent.toLowerCase();
    const isWeiXin = /MicroMessenger/i.test(ua);
    this.setState({
      isWeiXin
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }
  
  onClose = () => {
    this.setState({
      visible: false,
    });
  }

  render() {
    let {videoList1, videoList2, videoList3, introList, isWeiXin } = this.state;

    return (
      <div className="xiaoniu">
        <ScrollToTopOnMount/>
        <div className="page">
          <div className="top">
            <img className="max" src={topImg} alt="G-STEPS X 小牛电动" />
            <div className="btn" onClick={this.showModal}>立即领取</div>
          </div>

          <div className="video-list">
            <div className="video-box">
              <div className="video-title-back">
                <div className="video-title free" />
              </div>
              <div className="content">
                <p>下班后 骑小牛 穿梭在城市中看风景</p>
                <p>亦或  放下工作 跟着音乐 自由舞蹈</p>
                <p>
                  Enjoy的  是这份洒脱的心情
                </p>
              </div>
              {
                videoList1.map((video, vdx) => (
                  <div className="video" key={vdx}>
                    <video poster={video.poster} src={video.videoUrl} controls
                          webkit-playsinline="true"
                          playsInline
                          x5-video-player-type="h5"
                          x5-video-player-fullscreen="true"
                          x5-video-orientation="portraint"
                    />
                  </div>
                ))
              }
            </div>
            <div className="video-box">
              <div className="video-title-back">
                <div className="video-title xdp" />
              </div>
              <div className="content">
                <p>一瞬间决定  骑着小牛 来一场远行 或 穿过那条小巷</p>
                <p>牛油是旅行的行动派</p>
                <p>回到街头 不止于旁观</p>
                <p>再精彩的观看 不如一次沉浸其中的体验</p>
              </div>
              <div className="video">
              {
                videoList2.map((video, vdx) => (
                  <div className="video" key={vdx}>
                    <video poster={video.poster} src={video.videoUrl} controls
                          webkit-playsinline="true"
                          playsInline
                          x5-video-player-type="h5"
                          x5-video-player-fullscreen="true"
                          x5-video-orientation="portraint"
                    />
                  </div>
                ))
              }
              </div>
            </div>
            <div className="video-box">
              <div className="video-title-back">
                <div className="video-title bsx" />
              </div>
              <div className="content">
                <p>谁说电动车 等同于土和平庸</p>
                <p>谁说没基础 不能学跳舞</p>
                <p>谁说旅行 只在远方</p>
                <p>谁说改变 与我无缘</p>
              </div>
              {
                videoList3.map((video, vdx) => (
                  <div className="video" key={vdx}>
                    <video poster={video.poster} src={video.videoUrl} controls
                          webkit-playsinline="true"
                          playsInline
                          x5-video-player-type="h5"
                          x5-video-player-fullscreen="true"
                          x5-video-orientation="portraint"
                    />
                  </div>
                ))
              }
            </div>
            <div className="normal-box intro-box">
            <div className="img-wrap">
                <img src={aboutGstepsImg} alt="关于 Gsteps" height="60" />
              </div>
              <div className="img-wrap">
                <img src={logoImg} alt="G-STEPS" height="20" style={{ 'marginLeft': '-20px' }} />
              </div>
              <div className="content">
                <p>G-STEPS 街舞工作室致力于让更多普通人爱上街舞，目前在北京有 10 家门店，立足于 HIP-HOP 文化发展，提供适合普通人的教学课堂。</p>
                <p>重视基本功、重视 OLD SCHOOL 课程，兼容 URBAN、MV DANCE 等。</p>
                <p>期望与更多舞者合作，期望更多普通人开始跳舞。</p>
              </div>
              {
                introList.map((intro, idx) => (
                  <div className="intro-item" key={idx}>
                    <img className="poster" src={intro.poster} alt={intro.title} />
                    <article className="intro-info">
                      <p>{intro.title}</p>
                      <p>{intro.content}</p>
                    </article>
                  </div>
                ))
              }
            </div>
            <div className="normal-box book-class-box">
              <div className="img-wrap">
                <img src={classImg} alt="领券/约课" height="60" />
              </div>
              <div className="content">
                <p>保存以下GSTEPS 小程序二维码，在微信中识别打开，即可查看更多舞蹈视频或约课</p>
              </div>
              <div className="img-wrap">
                <img src={miniCodeImg} alt="Gsteps 小程序二维码" width="180" />
              </div>
            </div>
          </div>

        </div>
        
        <Footer />

        <Modal
          title={false}
          closable={false}
          transparent={true}
          animationType={'fade'}
          onClose={this.onClose}
          visible={this.state.visible}
          className="result-modal"
        >
          <div>
            <img src={successTitleImg} alt="恭喜" />
          </div>
          <p className="dash-line"></p>
          <p className="msg"><span className="red">截图</span> 以下 G-STEPS 小程序二维码，并在微信识别打开，支付 <span className="red">1.9 元</span>（原价 39.9 元）即可体验一节街舞课程。</p>
          <img src={miniCodeImg} alt="Gsteps 小程序二维码" width="180" />
          <div className="btn-save"> { isWeiXin ? '长按图片识别小程序' : '长按保存图片去微信打开' } </div>
        </Modal>
      </div>
    );
  }
}
