import axios from "axios";
import moment from "moment";
import {
  ResponseBack, OssConfig, WechatData, CheckLoginInfo, ActivityLive, ActivityDetail,
  SigninInfo, BasicInfo, CommentInfo, ActivityFast, SigninInfoList, CommentNoticeData, UserDetailInfo,
  FeedbackContent, CourseDetail,
  StudioGuide, CommonRedisMark
} from "./model";

export const NOT_LOGIN_CODE = 30002

/**
 * 验证接口返回时，是否登录
 * @param data
 */
function locationLogin(data: any, url?: string) {
  if (data.code === NOT_LOGIN_CODE) {
    // 登录
    let back = encodeURIComponent(window.location.href);
    if (url) {
      back = url;
    }
    window.location.href = '/api/user/oauth?current_url=' + back;
    return false;
  }
  return true;
}
// 发起 post 请求
async function post(url='', data= {}):Promise<ResponseBack> {
  return (await axios.post(url, toFormData(data))).data;
}
// 发起 get 请求
async function get(url=''):Promise<ResponseBack> {
  try {
    const res = await axios.get(url);
    if(res && res.data) {
      return res.data
    }
  }catch (e) {}

  return {
    code: -1,
    msg: '',
    res: {}
  }
}

// 请求的同时检查有没有登录，没有登录则去登录
async function postAndCheckLogin(url='', data= {}, autoLogin=true):Promise<ResponseBack> {
  let res = (await axios.post(url, toFormData(data))).data;
  if(autoLogin) {
    if(locationLogin(res)) {
      return res;
    } else {
      return res;
    }
  } else {
    return res;
  }

}
function toFormData(params: any) {
  const formdata = new FormData();
  Object.entries(params).forEach(ele => {
    // @ts-ignore
    formdata.append(ele[0], ele[1]);
  });
  return formdata
}


/**
 * 获取微信配置数据
 */
export async function getWechatData(): Promise<WechatData> {
  let data = await postAndCheckLogin('/api/wechat/wechatjsapi', {
    current_url: window.location.href
  });
  if(data.code === 0) {
    return data.res;
  }
  return new WechatData();
}

export async function checkLogin(autoLogin=true): Promise<CheckLoginInfo> {
  let data = await postAndCheckLogin('/api/user/checklogin', {}, autoLogin);
  if(data.code === 0) {
    let info:CheckLoginInfo = data.res;
    if(!info.login && autoLogin) {
      let back = encodeURIComponent(window.location.href);
      window.location.href = '/api/user/oauth?current_url=' + back;
    }else {
      return data.res;
    }
  }
  return new CheckLoginInfo();
}

export async function getOssConfig(): Promise<OssConfig> {
  let data = await postAndCheckLogin('/api/common/ossconfig');
  if(data.code === 0) {
    return data.res;
  }
  return new OssConfig();
}

export async function addCourseVideo(course_id:number, url:string, width:number=0, height:number=0): Promise<ResponseBack> {
  let data = await postAndCheckLogin('/api/course/video',
    {
      course_id: course_id,
      video_url: url,
      video_width: width,
      video_height: height,
    });
  return data;
}

export async function addActivityVideo(activity_id:number, url:string, width:number=0, height:number=0, type=1): Promise<ResponseBack> {
  let data = await postAndCheckLogin('/api/activity/addvideo',
    {
      activity_id: activity_id,
      video_url: url,
      video_width: width,
      video_height: height,
      type: type, // 1课堂视频，2本节课的预告
    });
  return data;
}


/**
 * 获取用户正在直播课的活动信息
 */
export async function getLatelyLiveActivity(): Promise<ActivityLive> {
   let data = await postAndCheckLogin('/api/live/lately/activity');
   if (data.code === 0) {
    return data.res;
   }
   return new ActivityLive();
}

/**
 * 获取课程信息信息
 */
export async function getActivityDetail(activity_id:number, get_collect:number=0, fast=0, get_activity_video=1, get_player=1): Promise<ActivityDetail> {
  let data = await postAndCheckLogin('/api/activity/detail',
    {
      activity_id: activity_id,
      get_collect: get_collect,
      get_activity_video: get_activity_video,
      fast: fast,
      get_player: get_player,
    });
  if (data.code === 0) {
    return data.res;
  }
  return new ActivityDetail();
}
/**
 * 获取课程组信息
 */
export async function getCourseDetail(course_id:number, get_more:number=0): Promise<CourseDetail> {
  let data = await postAndCheckLogin('/api/course/info',
    {
      course_id: course_id,
      get_more: get_more,
    });
  if (data.code === 0) {
    return data.res;
  }
  return new CourseDetail();
}

/**
 * 返回用户这趟课程的签到情况
 * @param activity_id
 * @return 0, 没有签到记录。1 已经签到
 *
 */
export async function getSigninInfo(activity_id: number): Promise<SigninInfo> {
  let data = await postAndCheckLogin('/api/signin/info',
    {
      activity_id: activity_id,
    });
  if (data.code === 0) {
    return data.res;
  }
  return new SigninInfo();
}


/**
 * 基础数据，舞种，教室，老师列表等
 */
export async function getCommonBasis(): Promise<BasicInfo> {
  const basisKey = 'queryBasisDatakey';
  let md5 = '';
  let storageData:any = {};
  if(localStorage) {
    const data:any = localStorage.getItem(basisKey);
    try {
      const dataJson =  JSON.parse(data);
      if (dataJson && dataJson.md5) {
        md5 = `${dataJson.md5}`;
        storageData = dataJson;
      }
    }catch (e) {
      console.error(e)
    }
  }
  const res = await post('/api/common/basis',
    {
      md5: md5,
      v2: 1,
    });
  if(res.code === 0) {
    let resData = res.res;
    if(!!md5 && resData && resData.md5 === md5) {
      return storageData;
    } else {
      let ossUrl = resData.oss_url;
      if(ossUrl) {
        resData = await get(ossUrl);
      }
    }
    localStorage.setItem(basisKey, JSON.stringify(resData));
    return resData;
  }
  return storageData;
}


/**
 * 获取留言
 * @param activityId
 * @param lastId
 */
export async function getCommentList(activityId:number, lastId:number=0): Promise<ResponseBack> {
  try {
    const query = `activity_id=${activityId}&start_id=${lastId}`
    const data = await get(`/api/live/comment/list?${query}`);
    return data;
  }catch (e){
    console.error('=--------------------------------')
    console.error(e)
  }

  return {
    code:-1,
    msg:'',
    res: {},
  }

  // if (data.code === 0) {
  //   return data.res;
  // }
  // return new CommentNoticeData();
}
/**
 * 发送留言
 * @param activityId
 * @param content
 */
export async function addComment(activityId: number, content:string): Promise<number> {
  let data = await postAndCheckLogin('/api/live/comment/add',
    {
      content: content,
      activity_id: activityId,
    });
  if (data.code === 0) {
    return data.res;
  }
  return 0;
}/**
 * 发送反馈
 * @param activityId
 * @param content
 */
export async function sendFeedback(activityId: number, content:number): Promise<number> {
  let data = await postAndCheckLogin('/api/live/feedback/add',
    {
      content: content,
      activity_id: activityId,
    });
  if (data.code === 0) {
    return data.res;
  }
  return 0;
}
/**
 * 获取学生反馈
 * @param activityId
 */
export async function getFeedbackList(activityId:number): Promise<FeedbackContent[]> {
  try {
    let data = await postAndCheckLogin('/api/live/feedback/list',
      {
        activity_id: activityId,
      });
    if (data.code === 0) {
      return data.res;
    }
  }catch (e){}

  return [];
}
export async function addLiveNotice(activityId: number, content:string): Promise<number> {
  try {
    let data = await postAndCheckLogin('/api/live/add/notice',
      {
        content: content,
        activity_id: activityId,
      });
    if (data.code === 0) {
      return data.res;
    }
  }catch (e){}

  return 0;
}

export async function changeLiveStatus(activityId: number, status:number): Promise<number> {
  let data = await postAndCheckLogin('/api/live/change/status',
    {
      status: status,
      activity_id: activityId,
    });
  if (data.code === 0) {
    return data.res.status;
  }
  return 0;
}


/**
 * 获取直播课程列表
 */
export async function getActivityFastList(): Promise<ActivityFast[]> {
  let paramData: any = {
    size: 1000,
    start_date: moment().format('YYYY-MM-DD'),
    course_way: 2,
  };
  let data = await postAndCheckLogin('/api/activity/queryfast',paramData);
  if (data.code === 0) {
    return data.res.list;
  }
  return [];
}

/**
 * 返回用户多个课程的签到情况
 * @param activity_id_list
 * @return 0, 没有签到记录。1 已经签到
 *
 */
export async function getSigninInfoList(activity_id_list: number[]): Promise<SigninInfoList> {
  let data = await postAndCheckLogin('/api/signin/listinfo', {
    activity_id_list: activity_id_list,
  });
  if (data.code === 0) {
    return data.res;
  }
  return new SigninInfoList();
}


/**
 * 确认签到
 * @param activity_id
 */
export async function signinConfirm(activity_id: number): Promise<ResponseBack> {
  return await postAndCheckLogin('/api/signin/confirm',{
    activity_id: activity_id
  });
}

/**
 * 获取用户信息，亲子账号等
 * @param activity_id
 */
export async function getUserInfo(): Promise<UserDetailInfo> {
  let data = await postAndCheckLogin('/api/user/info', {
  });
  if (data.code === 0) {
    return data.res;
  }
  return <UserDetailInfo>{};
}/**
 * 切换亲子账号登录
 * @param relationUserId
 */
export async function relationLogin(relationUserId:number): Promise<ResponseBack> {
  return await postAndCheckLogin('/api/user/relationlogin', {
    relation_user_id: relationUserId
  });

}

export async function getStudioList(): Promise<StudioGuide[]> {
  let res = (await axios.post('/api/studio/list', toFormData({}))).data;
  if(res.code === 0) {
    return res.res;
  }
  return [];
}

export async function addOnlineCourseFeedback(content='', score:number, activityId:number): Promise<ResponseBack> {
  const params = {
    type: 4, // 直播反馈
    content: content,
    score: score,
    for_id: activityId,
  };
  return (await axios.post('/api/feedback/add', toFormData(params))).data;
}

export async function getNowTime(): Promise<string> {
  let res = (await axios.post('/api/common/nowdate', toFormData({}))).data;
  let date = '';
  if(res.code === 0) {
    let fmt = 'YYYY-MM-DD HH:mm:ss';
    try {
      let dateI = moment();
      let resultData = res.data;
      if(isNaN(Number(resultData))) {
        dateI = moment(Number(resultData) *1000)
      }
      if(dateI.isValid()) {
        date = dateI.format(fmt);
      }
    } catch (e) {
      date = moment().format(fmt);
    }
    if (!date) {
      date = moment().format(fmt);
    }
  }
  return date;
}

export async function commonRedisMark(key: string, data: string = '', ex=(60 * 60 * 24 * 365), onlyGet=1, markUser=true): Promise<CommonRedisMark> {
  let res = (await axios.post('/api/common/redis/mark', toFormData({
    key: key,
    data: data,
    ex: ex,
    only_get: onlyGet,
    mark_user: markUser?1:0,
  }))).data;
  if(res.code === 0) {
    return res.res;
  }
  return new CommonRedisMark();
}

export async function createMiniUrl(params={}): Promise<string> {
  let res = (await axios.post('/api/mini/urllink', toFormData(params))).data;
  if(res.code === 0) {
    return res.res.url;
  }
  return '';
}
