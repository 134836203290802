import React from 'react';
import './index.scss';
import {ScrollToTopOnMount} from "../../components/top";
import {RouteComponentProps} from "react-router";
import {Button, Toast, Modal, Progress, InputItem} from 'antd-mobile';
import Loading  from "../../components/loading/index";
import Footer from '../../components/footer/footer';
import * as PostService from "../../service/post";

const alert = Modal.alert;

interface State {
  loading: boolean,
  courseId: number,
}
interface Props extends RouteComponentProps {
}

const GSTEPS_MINI_QR= require('../../static/img/miniqr.png');
const GSTEPS_POSTER = require('../../static/img/gsteps-poster.png');

export class CourseDetail extends React.PureComponent<Props, State> {

  constructor(p: any) {
    super(p);
    // 参数-课程ID
    const courseId = p.match.params.id;
    if(!courseId || courseId <= 0) {

    }
    // @ts-ignore
    this.state = {
      loading: true,
      courseId: courseId,
    }
  }

  componentWillMount(): void {
    this.initCourseDetailData().then(() => {})
  }

  // 加载直播课程详情
  async initCourseDetailData(): Promise<boolean> {
    // const info = await PostService.checkLogin();
    // if(info.login) {
      
    // } else {
    //   alert('提示', '你还没有登录哟', [{text: '确定', onPress: () => {}}]);
    // }
    this.setState({
      loading: false,
    });
    return true;
  }

  render() {
    let {loading} = this.state;

    if(loading) {
      return <Loading></Loading>;
    }

    return (
      <div className="course-detail-container">
        <div className="course-video">
          <div className="video-paly"></div>
        </div>

        <div className="course-detail-content">
          <article className="headline">
            <h3>WAACKING常规课 《Lets get loud》</h3>
            <p className="txt">3 人已预约</p>
          </article>

          <article className="intro">
            <div className="title">
              <label>NANA 老师</label>
            </div>
            <div className="poster">
              <img src={GSTEPS_POSTER} alt="G_STEPS" />
            </div>
            <article className="content">
              <p>毕业院校：北京现代音乐学院擅长舞种：Hip-Hop JAZZ2011年正式加入由韩国街舞冠军DROP和BABYSLEEK创建的女子HipHop团队-D.B Clippers2013年加入北京PureSoul2014年KOD世界精英邀请赛齐舞世界6强2015年美丽中国全国街舞大赛亚军</p>
            </article>
          </article>

          <article className="intro">
            <div className="title">
              <label>直播开始时间</label>
            </div>
            <div className="calendar-card">
              <aside>
                <p className="date">3 月 15 日</p>
                <p className="time">01:00-12:00 am</p>
              </aside>
              <aside className="weekday">
                <p>周</p>
                <p>六</p>
              </aside>
            </div>
          </article>
          <br/>

          <article className="intro">
            <div className="title">
              <label>加老师微信查询直播课</label>
            </div>
            <div className="poster">
              <img src={GSTEPS_MINI_QR} alt="G_STEPS" width="100" />
            </div>
          </article>
          <br/>
        </div>

        <div className="mini-qrcode">
          <article>
            <p>预约课程/查看课程视频</p>
            <p>请长按并识别右侧小程序，在小程序中进行哦</p>
          </article>
          <article className="qrcode-img">
            <img src={GSTEPS_MINI_QR} alt="G-STEPS" />
          </article>
        </div>
        <ScrollToTopOnMount/>
        <Footer color={'black'} />
      </div>
    );
  }
}
