import React from 'react';
import './index.scss';
import {ScrollToTopOnMount} from "../../components/top";
import {RouteComponentProps} from "react-router";
import {Button, Toast, Modal, Progress, InputItem} from 'antd-mobile';
import Loading  from "../../components/loading/index";
import Footer from '../../components/footer/footer';
import * as PostService from "../../service/post";
import {ActivityFast} from "../../service/model";

const alert = Modal.alert;

interface State {
  loading: boolean,
  tabIndex: number,
  activityFast: ActivityFast,
}
interface Props extends RouteComponentProps {
}

const GSTEPS_POSTER = require('../../static/img/gsteps-poster.png');

export class CourseList extends React.PureComponent<Props, State> {

  constructor(p: any) {
    super(p);

    this.state = {
      loading: true,
      tabIndex: 1,
      activityFast: new ActivityFast()
    }
  }

  componentWillMount(): void {
    this.initCourseListData().then(() => {})
  }

  // 加载直播课程列表
  async initCourseListData(): Promise<boolean> {
    // const info = await PostService.checkLogin();
    // if (info.login) {
    //   let res = await Promise.all([
    //     PostService.getActivityFastList()
    //   ]);
    //   let list = res[0];
    //   console.log('list', list);
    //   this.setState({
    //     loading: false,
    //   })
    // } else {
    //   alert('提示', '你还没有登录哟', [{text: '确定', onPress: () => {}}]);
    // }
    this.setState({
      loading: false,
    })
    return true;
  }

  // 捕获 TAB 切换
  clickTab = (index:number, e: any) => {
    this.setState({
      tabIndex: index
    })
  }

  // 捕获去直播间
  clickToLiveRoom = (id:number, e: any) => {
    this.props.history.push(`/course-detail/${id}`);
  }

  render() {

    let {loading, tabIndex} = this.state;

    if(loading) {
      return <Loading></Loading>;
    }

    return (
      <div className="course-list-container">
        <div className="gsteps-poster">
          <img src={GSTEPS_POSTER} alt="G-STEPS" />
        </div>
        <div className="course-list-content">
          <div className="course-tabs">
            <aside className={`tab ${tabIndex === 1 && 'active'}`} onClick={this.clickTab.bind(this, 1)}>我的课程</aside>
            <aside className={`tab ${tabIndex === 2 && 'active'}`} onClick={this.clickTab.bind(this, 2)}>全部课程</aside>
          </div>

          {/* 我的课程列表 */}
          <div style={{ 'display': tabIndex === 1 ? 'block' : 'none' }}>
            <article className="headline">
              <label className="back">Today</label>
              <label className="front">正在直播课程</label>
            </article>

            <section className="course-item">
              <figure className="poster">
                <img src={GSTEPS_POSTER} alt="G-STEPS" />
              </figure>
              <article className="course-info">
                <p className="title">WAACKING常规课 《Lets get loud》</p>
                <p className="name">NANA老师 2020.2.28 19:00</p>
                <p>新手 | 共 4 节</p>
              </article>
              <Button className="button-yellow" type="warning" onClick={this.clickToLiveRoom.bind(this, 1)}>进入课堂</Button>
            </section>

            <section className="course-item">
              <figure className="poster">
                <img src={GSTEPS_POSTER} alt="G-STEPS" />
              </figure>
              <article className="course-info">
                <p className="title">WAACKING常规课 《Lets get loud》</p>
                <p className="name">NANA老师 2020.2.28 19:00</p>
                <p>新手 | 共 4 节</p>
              </article>
              <Button className="button-yellow" type="warning" onClick={this.clickToLiveRoom.bind(this, 1)}>进入课堂</Button>
            </section>

            <article className="headline">
              <label className="back">Will Begin</label>
              <label className="front">即将开始课程</label>
            </article>

            <section className="course-item">
              <figure className="poster">
                <img src={GSTEPS_POSTER} alt="G-STEPS" />
              </figure>
              <article className="course-info">
                <p className="title">WAACKING常规课 《Lets get loud》</p>
                <p className="name">NANA老师 2020.2.28 19:00</p>
                <p>新手 | 共 4 节</p>
              </article>
              <Button className="button-yellow" type="warning" onClick={this.clickToLiveRoom.bind(this, 1)}>进入课堂</Button>
            </section>
          </div>
          
          {/* 全部课程列表 */}
          <div style={{ 'display': tabIndex === 2 ? 'block' : 'none' }}>
            全部课程列表
          </div>
        </div>
        <ScrollToTopOnMount/>
        <Footer color={'black'} />
      </div>
    );
  }
}
