import React from 'react';
import './home.scss';
import {ScrollToTopOnMount} from "../../components/top";
import {RouteComponentProps} from "react-router";
import moment from "moment";
import {Button, Toast, Modal, Progress, InputItem} from 'antd-mobile';

import VideoPlayer  from "../../components/live-video/info";
import Loading  from "../../components/loading/index";
import * as PostService from "../../service/post";
import {
  ActivityDetail,
  SigninInfo,
  BasicInfo,
  CommentInfo,
  CheckLoginInfo,
  FeedbackContent,
  CommentNoticeData
} from "../../service/model";

import {  VariableSizeList } from 'react-window';
import {NOT_LOGIN_CODE} from "../../service/post";
const alert = Modal.alert;
const prompt = Modal.prompt;
const operation = Modal.operation;

class TmpTeacher {
  id = 0;
  name = '';
  avatar = '';
}

interface State {
  loading: boolean,
  activityId: number,
  activityDetail: ActivityDetail,
  teacherList: TmpTeacher[],

  signinInfo: SigninInfo,
  checkLoginInfo: CheckLoginInfo,
  basicInfo: BasicInfo,
  commentInfoList: CommentInfo[],
  feedbackList: FeedbackContent[],
  content: string,
  close: boolean,
  notice: string,
  liveStatus: number,  // 直播状态，1 未开播，2正在播，3结束
}
interface Props extends RouteComponentProps {
}

let width = window.innerWidth;
let height = window.innerHeight;
let commentTimeLength = 2000;  // 留言轮询时长
export class LiveComment extends React.PureComponent<Props, State> {
  listRef:any = null;

  constructor(p: any) {
    super(p);
    const activityId = p.match.params.id;
    if(!activityId || activityId <= 0) {

    }
    // @ts-ignore
    this.state = {
      loading: true,
      activityId: activityId,
      activityDetail: new ActivityDetail(),
      teacherList: [],
      signinInfo: new SigninInfo(),
      checkLoginInfo: new CheckLoginInfo(),
      // basicInfo: null,
      commentInfoList: [],
      content: '',
      close: false,
      notice: '',
      liveStatus: 2,
      feedbackList: [],
    }
  }

  componentWillMount(): void {

    this.checkInfo().then(()=>{
      width = window.innerWidth;
      height = window.innerHeight;
    })
  }

  componentWillUnmount(): void {
    this.stopTimeoutKey = true;
  }

  goHome() {

  }

  async checkInfo(): Promise<boolean> {
    const info = await PostService.checkLogin(false);
    if(info.login) {
      let res = await Promise.all([PostService.getActivityDetail(this.state.activityId,0, 1, 1, 0), PostService.getCommonBasis()]);
      let detail = res[0];
      let basicInfo = res[1];
      if(!detail.id || detail.course_way !== 2) {
        alert('提示', '这不是直播课哟', [
          { text: '确定', onPress: () => {
              this.goHome();
            }},
        ]);
      } else if(info.role_info.role_id === 2 || info.role_info.perms.length > 0) {
        // 老师和管理员能进

        this.setState({
          loading: false,
        });
        this.startGetComment();
        this.startReloadPage();
      }  else {

        this.setState({
          loading: false,
        });
        this.startGetComment();
        this.startReloadPage();

        // alert('提示', '无权限', [
        //   { text: '确定', onPress: () => {
        //       this.goHome();
        //     }},
        // ]);
      }

      let teacherIds:number[] = [];
      if(detail.teacher_ids) {
        teacherIds = detail.teacher_ids;
      }else {
        teacherIds.push(detail.teacher_id)
      }
      let teacherList = teacherIds.map(id=>{
        let res = new TmpTeacher();
        if(basicInfo) {
          let teacher = basicInfo.teacher.find(t=>t.teacher_id === id);
          if(teacher) {
            res.avatar = teacher.teacher_avatar || teacher.user_avatar;
            res.name = teacher.name;
            res.id = teacher.teacher_id;
          }
        }
        return res;
      }).filter(a=>a.id > 0);



      this.setState({
        activityDetail: detail,
        teacherList: teacherList,
        basicInfo: basicInfo,
        checkLoginInfo: info,
      });
    } else {
      this.goModelAndLogin();
    }
    return true;
  };

  goModelAndLogin = ()=>{
    alert('还没有登录哟',
      <>
        {/*<div className="no-login-text"></div>*/}
        <div className="no-login-text-2">仅支持单个设备进入直播间，如出现重复登录情况，请将其他设备退出直播间即可</div>
      </>
      , [
        { text: '去登录', onPress: () => {
            PostService.checkLogin().then();
          }},
      ]);
  }

  stopTimeoutKey = false;
  startGetComment = ()=>{
    let fun = ()=>{
      setTimeout(async ()=>{
        if(!this.stopTimeoutKey) {
          await this.getCommentList().then();
          fun();
        }
      }, commentTimeLength);
    };
    fun();
  };

  // 5分钟，刷新一次页面
  startReloadPage = ()=>{
    setTimeout( ()=>{
      if(!this.stopTimeoutKey) {
        Toast.loading('页面即将自动刷新');
        setTimeout(()=>{
          window.location.reload();
        }, 3000)
      }
    }, 5*60*1000);
    // }, 10*1000);
  };

  lastCommentId = 0;
  // 获取聊天信息
  getCommentList = async ()=>{
    let { activityDetail, commentInfoList, liveStatus, feedbackList} = this.state;

    const res = await Promise.all([PostService.getCommentList(activityDetail.id, this.lastCommentId)
      , PostService.getFeedbackList(activityDetail.id)]);
    let commentData = new CommentNoticeData();
    if(res[0].code === 0) {
      commentData = res[0].res;
    }else if(res[0].code === NOT_LOGIN_CODE) {
      // 重新登录
      window.location.reload();
      return;
    }
    const thisFeedbackList = res[1];
    if (commentData) {
      if(commentData.comment_list.length > 0) {
        commentData.comment_list.forEach(item=>{
          if(item.comment_id > this.lastCommentId) {
            this.lastCommentId = item.comment_id
          }
          let content = `${item.user_name}${item.content}`;
          let length = content.replace(/[\u4e00-\u9fa5]/gi, '**').length + 1;

          let fontWidth = 400/56;

          let height = 64;
          let num = 26;

          height = height + Math.floor(length / num) *40;
          item.height = height;
        });
        let newCommentInfoList = [...commentInfoList, ...commentData.comment_list].sort((a,b)=>{
          return a.comment_id - b.comment_id;
        });
        this.setState({
          commentInfoList: newCommentInfoList,
        },()=>{
          if(this.listRef) {
            this.listRef.resetAfterIndex((newCommentInfoList.length - commentData.comment_list.length), false);
            this.scrollCommentBottom();
          }
        });
      }

      // 处理公告
      if(commentData.notice) {
        let notice = this.state.notice;
        if(commentData.notice.is_del === 1) {
          notice = '';
        } else if(!!commentData.notice.content) {
          notice = commentData.notice.content;
        }
        if(this.state.notice !== notice) {
          this.setState({
            notice: notice,
          })
        }
      }
    }

    if (thisFeedbackList) {
      let showList: FeedbackContent[] = [];
      // 只显示峰值, 60s 清空一次
      if(thisFeedbackList.length > 0) {
        for(let f of thisFeedbackList) {
          let find = feedbackList.find(tf => tf.content === f.content);
          if(!this.resetFeedbackData && find && find.num > f.num) {
            if(!!this.feedbackTimeoutKey) {
              clearTimeout(this.feedbackTimeoutKey)
            }
            this.resetFeedbackData = false;
            this.feedbackTimeoutKey = setTimeout(()=>{
              this.resetFeedbackData = true;
            }, 60*1000);
            f = find;
          }
          showList.push(f);
        }
      }
      this.setState({
        feedbackList: showList,
      })
    }
  };

  feedbackTimeoutKey:any = null;
  resetFeedbackData = false;


  scrollCommentBottom = ()=>{
    setTimeout(()=>{
      this.listRef && this.listRef.scrollToItem(this.state.commentInfoList.length, 'end');
    });
  };


  isSendingMsg = false;  // 标记正在发送消息
  sendMsg = (event:any)=>{
    if(event && event.keyCode === 13) {
      const content = event.currentTarget.value;
      if(!this.isSendingMsg && content) {
        let { activityDetail} = this.state;

        this.isSendingMsg = true;
        PostService.addComment(activityDetail.id, content).then(()=>{
          this.isSendingMsg = false;
          this.getCommentList();
        });
        this.setState({content: ''}); // 设置值为空字符串
      }
    }
  };

  inputOnFocus = (e:any) => {
    setTimeout(function(){
      if(e.target && e.target.scrollIntoView) {
        e.target.scrollIntoView(true);
      }
      // true:元素的顶端将和其所在滚动区的可视区域的顶端对齐; false:底端对齐。
    },200);  // 延时 == 键盘弹起需要时间
  };

  blur = (e:any) => {
    setTimeout(function(){
      window.scrollTo(0, 0);
    },200);  // 延时 == 键盘弹起需要时间
  };

  handleChange = (e:any)=> {
    this.setState({content: e.target.value});
  };

  close = ()=> {
    this.setState({close: !this.state.close}, ()=>{
      this.scrollCommentBottom();
    });
  };

  glistRef = (ref:any)=>{
    this.listRef = ref;
  };

  editNotice = ()=>{
    let checkLoginInfo = this.state.checkLoginInfo;
    let notice = this.state.notice;
    if(checkLoginInfo.login && checkLoginInfo.role_info.perms.length > 0 ) {
      prompt(
        '修改公告',
        '',
        [
          { text: '取消' },
          { text: '提交', onPress: content => {
            if(!content) content = '';
              PostService.addLiveNotice(this.state.activityId, content).then((id)=>{
                this.setState({
                  notice: content
                })
              });
          }},
        ],
        'default', notice)
    }
  };

  reload = ()=>{
    window.location.reload();
  };

  render() {
    let { loading, activityDetail, checkLoginInfo, basicInfo, commentInfoList,
      content, close, notice, liveStatus, feedbackList, teacherList} = this.state;

    if(loading) {
      return <Loading></Loading>;
    }

    let teacherAvatar = activityDetail.teacher_icon;
    let teacherName = activityDetail.teacher_name;
    if (basicInfo) {
      if(basicInfo.teacher) {
        let teacher = basicInfo.teacher.find(t=>t.teacher_id === activityDetail.teacher_id);
        if(!!teacher) {
          teacherAvatar = teacher.teacher_avatar || teacher.user_avatar;
          teacherName = teacher.name;
        }
      }
    }

    const commentListStyle = {
      height: height*0.7,
      width: 350,
    };
    if(close) {
      commentListStyle.height = 60;
      commentTimeLength = 5000;
    } else {
      commentTimeLength = 5000;
    }

    const getItemSize = (index:number) => {
      let comment = commentInfoList[index];
      if(comment){
        return comment.height;
      }
      return 0;
    };

    const Row = (data:any) => {
      if(commentInfoList) {
        let comment = commentInfoList[data.index];
        if(comment){
          let time = '';
          if(comment.create_time) {
            time = moment(comment.create_time).format('M月D H:mm:ss');
          }
          return (
            <div key={`comment-${comment.comment_id}`} style={data.style}>
              <span className="time">{time}</span>
              <div className="comment">
                <span className="name">{comment.user_name}</span>
                <span className="text">{comment.content}</span>
              </div>

            </div>
          )
        }
      }
      return (<></>)
    };

    let commentListBox = (<></>);
    if(commentInfoList && commentInfoList.length > 0) {
      commentListBox = (
        <>
          <VariableSizeList
            ref={this.glistRef}
            height={commentListStyle.height}
            itemCount={commentInfoList.length}
            itemSize={getItemSize}
            width={commentListStyle.width}
            estimatedItemSize={20}
          >
            {Row}
          </VariableSizeList>
        </>);
    }
    let noticeContent = notice;
    if(!noticeContent && checkLoginInfo.login && checkLoginInfo.role_info.perms.length > 0) {
      noticeContent = '还未设置公告，点击可编辑';
    }
    let noticeContentBox = (<></>);
    if (noticeContent) {
      noticeContentBox = (
        <>
          <div className="notice" onClick={this.editNotice}>
            {noticeContent}
          </div>
        </>);
    }

    let feedbackContent = (<></>);

    if(feedbackList && feedbackList.length > 0) {
      feedbackContent = (<>
        <div className="feedback-box">
          {feedbackList.map(f=>{
            let cn = ['feedback'];
            if(f.content === 1) {
              cn.push('happy')
            } else if(f.content === 2) {
              cn.push('sad')
            }
            if (cn.length > 1) {
              return (
                <div key={`feedback-box${f.content}`} className={cn.join(' ')} >X{f.num}</div>
              );
            }else {
              return (<></>);
            }
          })}
        </div>
      </>);

    }

    let teacherContent = teacherList.map(teacher=>{
      return (
        <div className="teacher" key={`tstttt_${teacher.id}`}>
          <div className="avatar">
            <img src={teacher.avatar} />
          </div>
          <span className="teacher-name">{teacher.name}</span>
        </div>
      );
    });

    return (
      <div className={`home-live-comment ${close?'closing':''}`}>
        <ScrollToTopOnMount/>
        {feedbackContent}

        <div className="top-opt-box">
          <div className="teacher-list">
            {teacherContent}

            <div className="course-name">
              {activityDetail.course_name}
            </div>
          </div>


          <div className="close" onClick={()=>{window.history.back()}}>返回</div>
        </div>

        <div className="comment-box">
          <div className="comment-input">
            <input type="text" onBlur={this.blur} value={content} onChange={this.handleChange} placeholder="与老师交流..." onKeyUp={this.sendMsg} />
          </div>
          <div className="comment-list">
            {commentListBox}
          </div>
          {noticeContentBox}
        </div>
      </div>
    );
  }
}
