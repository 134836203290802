const wx = require('weixin-js-sdk');

export function urlArgs() {
  let args: any = {};
  let query = window.location.search.substring(1);
  let pairs = query.split("&");
  for (let i = 0; i < pairs.length; i++) {
    let pos = pairs[i].indexOf("=");
    if (pos === -1) continue;
    let name = pairs[i].substring(0, pos);
    let value = pairs[i].substring(pos + 1);
    value = decodeURIComponent(value);
    args[name] = value;
  }
  return args;
}

// 返回小程序
export function miniBack() {
  if (wx && wx.miniProgram && wx.miniProgram.getEnv) {
    wx.miniProgram.getEnv((res: any) => {
      if (res.miniprogram) {
        // 在小程序环境下
        wx.miniProgram.navigateBack({
          delta: 1,
          // fail: () => {
          //   wx.miniProgram.switchTab({
          //     url: "/pages/index/index"
          //   })
          // },
          complete: (e: any) => {
            wx.miniProgram.switchTab({
              url: "/pages/index/index"
            })
          }
        });

      }
    })
  }
}

/**
 * 检查角色权限
 */
export function checkRole(systemPageList:string[]=[], allowRoleList:string[]=[]) {
  let allow = false;
  if(systemPageList.length > 0) {
    for(const name of systemPageList) {
      const find = allowRoleList.find(a=>a===name);
      if(find) {
        allow = true;
        break;
      }
    }
  }

  return allow;
}
