import React from 'react';
import './mini-file-up.scss';
import {ScrollToTopOnMount} from "../../components/top";
import {RouteComponentProps} from "react-router";
import * as UtilsService from "../../service/utils";
import * as PostService from "../../service/post";
import {Button, Toast, Modal, Progress} from 'antd-mobile';
import {BasicInfo, OssConfig} from "../../service/model";
import Back from "../../components/back/index";
import {getActivityDetail} from "../../service/post";

const alert = Modal.alert;

const OSS = require('ali-oss');

interface State {
  config: OssConfig,
  fileUping: boolean, // 正在上传
  completeNum: number, // 上传进度，百分比
  showPage: boolean,
  title: string,
  activityTitle: string,
  subActivityTitle: string,
  size: string, // 当前上传文件的大小
  basicInfo: BasicInfo,

}

interface Props extends RouteComponentProps {
}

/**
 * 给小程序上传视频文件使用
 */
let id = 0; // 上传视频的归属id, 课程组id 或者 课程id
let type = 0;// 上传视频的归属, 1课程组, 2课程，3课程（单个课程预告）
export class MiniFileUp extends React.PureComponent<Props, State> {

  constructor(p: any) {
    super(p);
    let title = '';
    let args = UtilsService.urlArgs();
    if (args && !!args['id'] && !!args['type'] && !isNaN(args['id']) && !isNaN(args['type'])) {
      id = Number(args['id']);
      type = Number(args['type']);
      if(type === 1) {
        title = '上传「课程组」预告视频';
      } else if(type === 2){
        title = '上传课堂视频';
      } else if(type === 3){
        title = '上传「单节课」预告视频';
      }
    }
    this.state = {
      fileUping: false,
      completeNum: 0,
      showPage: false,
      config: new OssConfig(),
      title: title,
      basicInfo: new BasicInfo(),
      activityTitle: '',
      subActivityTitle: '',
      size: '',
    };
  }

  async componentWillMount(): Promise<void> {
    if (id > 0 && type > 0) {
      Toast.loading('加载中...', 0);
      let list = await Promise.all([PostService.getOssConfig(), PostService.getCommonBasis()]);
      Toast.hide();
      const config = list[0];
      const basicInfo = list[1];
      if (config && config.secret && config.secret !== '') {
        this.setState({
          config: config,
          basicInfo: basicInfo,
          showPage: true,
        });
      } else {
        // 获取上传参数失败
        this.back('获取config 失败，'+ config);
        return;
      }
      let activityTitle = '';
      let subActivityTitle = '';
      if(type === 2 || type === 3) {
        let activity = await PostService.getActivityDetail(id, 0, 1, 0, 0);
        let teacherList:string[] = [];
        if(basicInfo.teacher && basicInfo.teacher.length>0 && activity.teacher_ids) {
          teacherList = activity.teacher_ids.map(id=>{
            let teacher = basicInfo.teacher.find(a=>a.teacher_id === id);
            if(teacher) {
              return teacher.name
            }
            return '';
          }).filter(n=>!!n);

        } else {

        }
        if(teacherList.length > 0) {
          activityTitle = `【${teacherList.join('、')}】${activity.course_name}`;
        }else {
          activityTitle = `【${activity.teacher_name}】${activity.course_name}`;
        }

        subActivityTitle = activity.start_time;
      } else if(type===1) {
        let course = await PostService.getCourseDetail(id);
        activityTitle = `${course.course_name}`;
      }

      this.setState({
        activityTitle: activityTitle,
        subActivityTitle: subActivityTitle,
      })

    } else {
      this.back('没有上传参数，请联系管理员')
    }
  }

  back = (msg: string) => {
    alert('提示', (<>
      <p>{msg}</p>
    </>), [
      { text: '确认', onPress: () => UtilsService.miniBack() },
    ]);
  };

  // 上传配置数据
  upOption = {
    progress: async (p:any, checkpoint:any) => {
      if(!isNaN(p)) {
        this.setState({
          completeNum: Number((p*100).toFixed(1)),
        });
      }

      // 断点记录点。 浏览器重启后无法直接继续上传，需用户手动触发进行设置。
      this.tempCheckpoint = checkpoint;
    },
    // parallel: 4,
    partSize: 1024 * 1024,//设置分片大小 1M
    timeout: 120000,//设置超时时间
  };

  tempCheckpoint = null; // 续传时的对象（记录上传的信息）
  maxUpTimes = 10; // 续传的最大次数
  currentUpTimes = 0; // 当前上次次数
  multiUpload = async (file: any, path:string, ):Promise<{code:number, msg: string}>=>{
    const {config} = this.state;
    let ossConfig = {
      endpoint: config.endpoint_fast,
      // region: config.endpoint_fast_short,
      // region: 'oss-accelerate.aliyuncs.com',
      accessKeyId: config.access_key,
      accessKeySecret: config.secret,
      bucket: config.bucket_name
    };
    let ret = {
      code: 0,
      msg: '',
    };
    let mime = file.type;
    let client = new OSS(ossConfig);
    try {
      let option = {
        mime: mime,
        checkpoint: null,
        ...this.upOption
      };
      if(this.tempCheckpoint) {
        option.checkpoint = this.tempCheckpoint;
      }
      this.currentUpTimes++;
      let result = await client.multipartUpload(path, file, option);
      // 上传成功
      this.currentUpTimes = 0;
      this.tempCheckpoint = null;
      return ret;
    } catch(e){
      console.log(e);
      if(this.currentUpTimes <= this.maxUpTimes) {
        return await this.multiUpload(file, path);
      }else {
        ret.code = -1;
        ret.msg = e;
        return ret;
      }
    }
  };

  uploadFile = async (file:any)=>{
    const {fileUping, config} = this.state;
    if (!file || !file.type || !(/video/gi).test(file.type)) {
      Toast.fail('请选择视频文件');
      return;
    }
    // 或者是添加到一个FormData
    let fileType = 'mp4'; // 文件格式
    let fileName = `${id}-${config.file_id}-${Date.now()}-${Math.ceil(Math.random()*100)}.${fileType}`;
    if(type === 1) {
      fileName = 'course'+fileName;
    } else if(type === 3)  {
      fileName = 'activity_preview'+fileName;
    } else  {
      fileName = 'activity'+fileName;
    }
    let path = config.path + fileName;
    let url = config.url+fileName;
    let size = '';
    if(file.size) {
      size = (file.size/1024/1024).toFixed(2)+'M'
    }
    this.setState({
      completeNum: 0,
      fileUping: true,
      size: size,
    });
    // 开始上传
    let status = await this.multiUpload(file, path).then(upStatus => {
      this.setState({
        completeNum: 0,
        fileUping: false,
      });
      if(upStatus.code === 0) {
        let promise = null;
        // 修改课程视频
        if(type === 1) {
          // 课程组上传
          promise = PostService.addCourseVideo(id, url);
        } else if(type === 2) {
          promise = PostService.addActivityVideo(id, url, 0, 0, 1);
        } else if(type === 3) {
          promise = PostService.addActivityVideo(id, url, 0, 0, 2);
        }
        if (!!promise) {
          return promise.then(status=>{
            if(status.code === 0) {
              return true;
            } else {
              Toast.fail('上传失败, '+ status.msg);
              return false;
            }
          })
        }else {
          Toast.fail('上传失败，type 值不对');
        }
      } else{
        Toast.fail('上传失败, '+ upStatus.msg);
      }
      return false;
    });
    return status;
  }
  // 选择了文件
  fileChange = async (event: any) => {
    const {fileUping, config} = this.state;
    if(fileUping) {
      Toast.fail('正在上传');
      return;
    }
    if(!config || !config.access_key || !config.secret) {
      Toast.fail('上传参数获取失败');
      return;
    }
    if( ![1,2,3].includes(type)) {
      Toast.fail('上传失败，type 值不对');
      return;
    }
    if (event && event.currentTarget && event.currentTarget.files && event.currentTarget.files.length >= 0) {
      let files = event.currentTarget.files;
      let failNum = 0;  // 上传失败的数量
      let index = files.length -1;
      for(let file of files){
        if(index < 0) {
          break;
        }
        // 倒序传
        file = files[index]
        //  多文件上传
        let status = await this.uploadFile(file);
        if(!status) {
          failNum = failNum + 1;
        }
        index = index - 1
      }
      if(failNum >= files.length) {
        Toast.fail('全部文件上传失败，请重试');
      } else {
        let msg = '上传成功，视频会在服务端进行转码，大概2-5分钟';
        if(failNum > 0) {
          msg = `${msg}，但是有${failNum}个上传失败`
        }
        alert('提示', (<>
          <p>{msg}</p>
        </>), [
          { text: '继续上传', onPress: () => {} },
          { text: '返回上一页', onPress: () => UtilsService.miniBack() },
        ]);
      }
    } else {
      Toast.fail('没有选取文件');
    }
  };

  /*判断客户端*/
  judgeClient() {
    let u = navigator.userAgent;
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
    // console.log('是否是Android：' + isAndroid); //true,false
    // console.log('是否是iOS：' + isIOS);
    if(isAndroid){
      return 'Android';
    }else if(isIOS){
      return 'IOS';
    }else{
      return 'PC';
    }
  }

  render() {
    const {fileUping, completeNum, showPage, title, size, activityTitle, subActivityTitle} = this.state;
    let loading = (<></>);
    let btnBox = (<></>);
    if(fileUping) {
      loading = (<>
        <div className="show-info">
          <div className="progress"><Progress percent={completeNum} position="normal" /></div>
          <div aria-hidden="true">{completeNum}%</div>
        </div>
        <div>{size}</div>
      </>);
    }
    if(showPage) {

      let m = this.judgeClient();
      let input = (<input disabled={fileUping} type="file" accept="video/*" onChange={this.fileChange} multiple />);
      if(m === 'Android') {
        // 安卓设置只能单个上传，不然有bug
        input = (<input disabled={fileUping} type="file" accept="video/*" onChange={this.fileChange} />);
      }

      btnBox = (<>
        <div className="file-btn">
          <Button className="button-yellow" type="warning" disabled={fileUping}>选择视频</Button>
          {input}
        </div>
        <p>ios和电脑端可以多选视频上传，安卓目前只能单个上传</p>
      </>);
    }
    return (
      <div className="mini-file-up">
        <ScrollToTopOnMount/>
        <Back />
        <div className="title">{title}</div>
        <div className="sub-title">{activityTitle}</div>
        <div className="sub-title">{subActivityTitle}</div>
        {loading}
        {btnBox}
      </div>
    );
  }
}
