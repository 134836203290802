import React from 'react';
import './index.scss';
import * as UtilsService from "../../service/utils";
const wx = require('weixin-js-sdk');
const imgback = require('../../static/img/back-light.png');
const logo = require('../../static/img/logo-2.jpeg');

export default class Back extends React.PureComponent<any, any> {

  constructor(pr: any) {
    super(pr);
    this.state = {
      isMiniProgram: false,
    }
  }
  componentWillMount(): void {
    if(wx && wx.miniProgram && wx.miniProgram.getEnv) {
      wx.miniProgram.getEnv((res:any)=> {
        if(res.miniprogram) {
          // 在小程序环境下
          this.setState({
            isMiniProgram: true,
          })
        }
      })
    }
  }

  backMiniProgram = ()=>{
    UtilsService.miniBack()
  };
  render() {
    let back = (<></>);
    if(this.state.isMiniProgram) {
      back = (
        <div className="back-mini-program" onClick={this.backMiniProgram}>
          <img className="back-img" src={imgback}/>
          <img className="back-logo" src={logo}/>
        </div>
      );
    }
    return (
      <>
        {back}
      </>
    );
  }
}
