import React from 'react';
import './home.scss';
import {RouteComponentProps} from "react-router";
interface State {
}
interface Props extends RouteComponentProps {
}
export class Year6Video extends React.PureComponent<Props, State> {

  constructor(p: any) {
    super(p);

    this.state = {
    }
  }

  componentWillMount(): void {
    window.location.replace('https://mp.weixin.qq.com/mp/homepage?__biz=MzAxOTUzNjkxNg%3D%3D&hid=1&sn=eb66665e960aae1ce3d16847229b80cb&scene=1&devicetype=iOS12.3.1&version=17000529&lang=zh_CN&nettype=3G+&ascene=7&session_us=gh_c6dc65b451df&fontScale=100');
  }
  render() {
    return (
      <div className="">

      </div>
    );
  }
}
