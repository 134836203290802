import React from 'react';
import './index.scss';

export default class Loading extends React.PureComponent<any, any> {
  render() {
    return (
      <>
        <div className="my-loading-box">
          <div className="lds-css">
            <div style={{width:"100%",height:"100%"}} className="lds-disk">
              <div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
