import React from 'react';
import './footer.scss';

export default class Footer extends React.Component<any, any> {

  constructor(p:any) {
    super(p);
  }

  render() {
    const { color = '' } = this.props; // 字体增加颜色：black
    let title = '';
    if(window.location.hostname.indexOf('gsteps.cn') >= 0) {
      title = '京ICP备15051017号-2';
    } else if (window.location.hostname.indexOf('gsteps.art') >= 0) {
      title = '京ICP备19037472号-1';
    }  else if (window.location.hostname.indexOf('gsteps.club') >= 0) {
      title = '京ICP备19037472号-2';
    }  else if (window.location.hostname.indexOf('gsteps.net') >= 0) {
      title = '京ICP备19037472号-3';
    } else if (window.location.hostname.indexOf('gsteps.live') >= 0) {
      title = '京ICP备19037472号-4';
    }  else if (window.location.hostname.indexOf('gsteps.vip') >= 0) {
      title = '京ICP备19037472号-5';
    } else if (window.location.hostname.indexOf('gstepstech.com') >= 0) {
      title = '京ICP备2023027712号-1';
    } else {
      title = '京ICP备19037472号';
    }

    return (
      <div className={`icp ${color}`}>
        <div>Copyright © 2013-2022 G-STEPS 版权所有</div>
        <a href="http://beian.miit.gov.cn" rel="noopener noreferrer" target="_blank">{title}</a>
      </div>
    );
  }
}
