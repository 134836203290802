import React from 'react';
import './home.scss';
import {ScrollToTopOnMount} from "../../components/top";
import {Jingzi} from "../jingzi/home";
import {RouteComponentProps} from "react-router";
import moment from "moment";
import {Button, Toast, Modal, Progress, InputItem} from 'antd-mobile';

import VideoPlayer  from "../../components/live-video/info";
import Loading  from "../../components/loading/index";
import * as PostService from "../../service/post";
import {
  ActivityDetail,
  SigninInfo,
  BasicInfo,
  CommentInfo,
  CheckLoginInfo,
  ActivityLive,
  CommentNoticeData
} from '../../service/model';

import {  VariableSizeList } from 'react-window';
import Feedback from "../../components/feedback/info";
import {NOT_LOGIN_CODE} from "../../service/post";
import {checkRole} from "../../service/utils";

const alert = Modal.alert;
const prompt = Modal.prompt;
const operation = Modal.operation;


class TmpTeacher {
  id = 0;
  name = '';
  avatar = '';
}

function getVideoDirectionKey(id=0) {
  return `live_video_Direction_${id}`;
}
function getVideoMirrorKey(id=0) {
  return `live_video_getVideoMirrorKey_${id}`;
}
function getChangeRuteKey(id=0) {
  return `live_video_getChangeRuteKey_${id}`;
}

interface State {
  loading: boolean,
  activityId: number,
  activityDetail: ActivityDetail,
  teacherList: TmpTeacher[],
  signinInfo: SigninInfo,
  checkLoginInfo: CheckLoginInfo,
  basicInfo: BasicInfo,
  commentInfoList: CommentInfo[],
  content: string,
  close: boolean,
  notice: string,
  liveStatus: number,  // 直播状态，1 未开播，2正在播，3结束
  liveQuality: number,  // 直播状态，1 超高清，2高清
  reloadVideo: boolean,  // 直播状态，1 超高清，2高清
  showJingzi: boolean,  // 是否显示镜子
  showFeedbackTips: boolean,  // 是否反馈提示框
  showCloseTips: boolean,  // 清屏
  box: {
    videoHeight: number,
    videoWidth: number,
    jingziHeight: number,
    jingziWidth: number,
  },
  innerHeight: number,
  innerWidth: number,
  videoDirection: number, // 1横屏，2竖屏
  otherActivityId: number, // 跳转到其他课程的id
  videoMirror: number, // 镜面，1 0度，2 180度
  showFeedBackBtn: boolean, // 显示反馈按钮
  showFeedBackModel: boolean, // 显示反馈按钮弹窗
}
interface Props extends RouteComponentProps {
}

// let width = window.innerWidth;
// let height = window.innerHeight;
let commentTimeLength = 2000;  // 留言轮询时长
const liveQualityKey = 'liveQualityKey';
// const q_720 = '_720';
// const q_720 = '_hd';
const q_720 = '_ud';

export class Live extends React.PureComponent<Props, State> {
  listRef:any = null;
  jingziChild:any = null;
  canUseMedia = !!(
    navigator.getUserMedia ||
    // @ts-ignore
    navigator.webkitGetUserMedia ||
    // @ts-ignore
    navigator.mozGetUserMedia ||
    (navigator.mediaDevices &&
    // @ts-ignore
    navigator.mediaDevices.getUserMedia));  // 是否能使用摄像头

  constructor(p: any) {
    super(p);
    const activityId = p.match.params.id;
    if(!activityId || activityId <= 0) {

    }
    let liveQuality = 1;
    let tmpLiveQuality = window.localStorage.getItem(liveQualityKey);
    if(tmpLiveQuality && !!Number(tmpLiveQuality)) {
        liveQuality = Number.parseInt(tmpLiveQuality);
    }


    // @ts-ignore
    this.state = {
      loading: true,
      activityId: activityId,
      activityDetail: new ActivityDetail(),
      teacherList: [],
      signinInfo: new SigninInfo(),
      checkLoginInfo: new CheckLoginInfo(),
      // basicInfo: null,
      commentInfoList: [],
      content: '',
      close: false,
      notice: '',
      liveStatus: 2,
      reloadVideo: true,
      liveQuality: liveQuality,  // 直播状态，1 超高清，2高清
      showJingzi: false, // 显示镜子
      showFeedbackTips: false,
      showCloseTips: false,
      box: {
        videoHeight: window.innerHeight,
        videoWidth: window.innerWidth,
        jingziHeight: window.innerHeight,
        jingziWidth: window.innerWidth,
      },
      innerHeight: window.innerHeight,
      innerWidth: window.innerWidth,
      videoDirection: 1,
      videoMirror: 1,
      otherActivityId: 0,
      showFeedBackBtn: false,
      // showFeedBackBtn: true,
      showFeedBackModel: false,
    }
  }

  componentWillMount(): void {
    let key = 'showFeedbackTips';
    let showFeedbackTips = window.localStorage.getItem(key);
    if(true || !showFeedbackTips) {
      this.setState({
        showFeedbackTips: true,
        showCloseTips: true,
      });
      window.localStorage.setItem(key, '1');
      setTimeout(()=>{
        this.setState({
          showFeedbackTips: false,
          showCloseTips: false,
        })
      }, 5000);
    }



    this.checkInfo().then(()=>{
      let innerHeight = window.innerHeight;
      let innerWidth = window.innerWidth;
      this.setState({
        box: {
          ...this.state.box,
          videoHeight: innerHeight ,
          videoWidth: innerWidth,
        },
        innerHeight:innerHeight,
        innerWidth: innerWidth,
      })

    })

    window.addEventListener('resize', this.onWindowResize.bind(this))
  }

  onWindowResize() {
    let width = window.innerWidth;
    let height = window.innerHeight;
    const {showJingzi, box} = this.state;

    if(showJingzi) {
      box.jingziWidth = width / 2 - 4;
      box.videoWidth = width / 2;
    } else {
      box.jingziWidth = width;
      box.videoWidth = width;
    }
    box.jingziHeight = height;
    box.videoHeight = height;

    this.setState({
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight,
      box: {...box}
    })

  }

  refresh() {
    window.location.reload();
  }



  // 监听 url id 是否有变化,如果有变化则重新加载数据
  // eslint-disable-next-line no-unused-vars
  componentWillReceiveProps(nextProps:any, nextContext: any) {
    const { id: newid } = nextProps.match.params;
    const id = this.state.activityId;
    if (id && newid && id && newid !== id) {
      this.setState({
        activityId: newid,
      });
      this.checkInfo().then(()=>{
        this.onWindowResize();
        // width = window.innerWidth;
        // height = window.innerHeight;
      })
    }
  };

  componentWillUnmount(): void {
    this.stopTimeoutKey = true;
    window.removeEventListener('resize', this.onWindowResize)
  }

  goHome() {

  }

  canShowJingzi() {
    const {innerHeight, innerWidth} = this.state;
    return (this.canUseMedia && innerWidth > 1000);
  }

  showJingzi() {
    if(this.canShowJingzi()) {
      const {showJingzi, box, innerHeight, innerWidth} = this.state;
      if(!showJingzi) {
        box.jingziWidth = innerWidth/2-4;
        box.videoWidth = innerWidth/2;
      } else {
        box.jingziWidth = innerWidth;
        box.videoWidth = innerWidth;
        // 关闭镜子
        if(this.jingziChild && this.jingziChild.closeUserMedia) {
          this.jingziChild.closeUserMedia()
        }
      }
      box.jingziHeight = innerHeight;
      box.videoHeight = innerHeight;
      this.setState({
        showJingzi: !showJingzi,
        box: {...box}
      })
    }
  }
  async relationLogin(uid:number): Promise<boolean> {
    if(uid > 0) {
      let res = await PostService.relationLogin(uid);
      if(res.code === 0) {
        window.location.reload()
      } else {
        alert('提示', `切换失败，${res.msg}`);
      }
    }
    return true;
  }

  async checkInfo(): Promise<boolean> {
    // debugger
    const info = await PostService.checkLogin(false);
    if(info.login) {
      let res = await Promise.all([PostService.getActivityDetail(this.state.activityId, 0, 1, 1, 0),
        PostService.getSigninInfo(this.state.activityId), PostService.getCommonBasis(), ]);
      let detail = res[0];
      let signin = res[1];
      let basicInfo = res[2];

      if(!detail.id || detail.course_way !== 2) {
        alert('提示', '这不是直播课哟', [
          { text: '确定', onPress: () => {
              this.goHome();
            }},
        ]);
      } else if(info.role_info.role_id !== 2 && info.role_info.perms.length <= 0 && signin.status !== 1) {
        // 如果有亲子，可以切换到亲子账号
        let userInfo = await PostService.getUserInfo();
        let uList:any[] = [];
        if(userInfo) {
          if(userInfo.parent_list && userInfo.parent_list.length > 0) {
            uList = [...uList, ...userInfo.parent_list]
          }
          if(userInfo.siblings_list && userInfo.siblings_list.length > 0) {
            uList = [...uList, ...userInfo.siblings_list]
          }
          if(userInfo.child_list && userInfo.child_list.length > 0) {
            uList = [...uList, ...userInfo.child_list]
          }
        }
        if(uList && uList.length > 0) {
          alert(
            '提示',
            '本账号还未预约本次课程，请选择切换账号哟',
            uList.map(u =>{
              return { text: `切换到：${u.name}`, onPress: () => {this.relationLogin(u.id)}, style: {color:"#000"} }})
          );
        } else {
          alert('提示', '你还没有报名这次课程哟', [
            { text: '确定', onPress: () => {
                this.goHome();
              }},
          ]);
        }


      } else if(!detail.live_play_url || (detail.live_status && detail.live_status === 1)) {
        // alert('提示', '直播还没有开始哟，等一会儿再来吧', [
        //   { text: '确定', onPress: () => {
        //       this.goHome();
        //     }},
        // ]);
        this.setState({
          liveStatus: 1,
          loading: false,
        })
      } else if((detail.live_status && detail.live_status === 3)) {
        this.setState({
          liveStatus: 3,
          loading: false,
        });
        // 课程结束，获取学员最新正在直播课程信息
        PostService.getLatelyLiveActivity().then((activityLive: ActivityLive)=>{
          if (activityLive && activityLive.activity_id > 0) {
            let tName: string = '';
            if (basicInfo && basicInfo.teacher) {
              const teacherInfo = basicInfo.teacher.find(t => t.teacher_id == activityLive.teacher_id);
              tName = teacherInfo ? `${teacherInfo['name']}-` : '';
            }
            alert('提示', `本次课程已结束，您预约的【${tName}${activityLive.course_name}】正在直播，去上课吧~`, [
              { text: '取消', onPress: () => {}},
              { text: '去上课', onPress: () => {
                this.props.history.push(`/live/${activityLive.activity_id}`);
                window.location.reload();
              }},
            ]);
          }
        });
      } else {
        if(signin.status === 1) {
          // 是学员，确认打卡
          PostService.signinConfirm(detail.id).then();
        }
        this.setState({
          loading: false,
        });
        this.startGetComment();
        // this.startGetShowFeedBack();
      }

      let teacherIds:number[] = [];
      if(detail.teacher_ids) {
        teacherIds = detail.teacher_ids;
      }else {
        teacherIds.push(detail.teacher_id)
      }

      let teacherList = teacherIds.map(id=>{
        let res = new TmpTeacher();
        if(basicInfo) {
          let teacher = basicInfo.teacher.find(t=>t.teacher_id == id);
          if(teacher) {
            res.avatar = teacher.teacher_avatar || teacher.user_avatar;
            res.name = teacher.name;
            res.id = teacher.teacher_id;
          }
        }
        return res;
      }).filter(a=>a.id > 0);


      this.setState({
        activityDetail: detail,
        teacherList: teacherList,
        signinInfo: signin,
        basicInfo: basicInfo,
        checkLoginInfo: info,
      },()=>{
        this.getVideoDirection().then();
        this.getVideoMirror().then();
        this.getRoute().then();
      });
    } else {
      this.goModelAndLogin();
    }
    return true;
  };

  goModelAndLogin = ()=>{
    alert('还没有登录哟',
      <>
        {/*<div className="no-login-text"></div>*/}
        <div className="no-login-text-2">仅支持单个设备进入直播间，如出现重复登录情况，请将其他设备退出直播间即可</div>
      </>
      , [
        { text: '去登录', onPress: () => {
            PostService.checkLogin().then();
          }},
      ]);
  }

  stopTimeoutKey = false;
  startGetComment = ()=>{
    let fun = ()=>{
      setTimeout(async ()=>{
        if(!this.stopTimeoutKey) {
          await this.getCommentList().then();
          fun();
        }
      }, commentTimeLength);
    };
    fun();
  };

  stopFeedBackTimeoutKey = false;
  startGetShowFeedBack = ()=>{

    let fun = ()=>{
      setTimeout(async ()=>{
        const {activityDetail} = this.state;
        let endTime = moment(activityDetail.start_time).add(activityDetail.duration, "minutes").subtract(5, 'minutes');
        if(!this.stopFeedBackTimeoutKey) {
          let res = await PostService.getNowTime();
          if(moment(res).isAfter(endTime)) {
            this.setState({
              showFeedBackBtn: true
            })
            this.stopFeedBackTimeoutKey = true;
          }else {
            fun();
          }
        }
      }, commentTimeLength*10);
    };
    fun();
  };

  lastCommentId = 0;
  // 获取聊天信息
  getCommentList = async ()=>{
    let { activityDetail, commentInfoList, liveStatus} = this.state;
    let commentData = new CommentNoticeData();

    const commentDataRes = await PostService.getCommentList(activityDetail.id, this.lastCommentId);
    if(commentDataRes) {
      if(commentDataRes.code === 0) {
        commentData = commentDataRes.res;
      }else if(commentDataRes.code === NOT_LOGIN_CODE) {
        // 重新登录
        window.location.reload();
        return;
      }
    }

    if (commentData ) {
      if(commentData.comment_list.length > 0) {
        commentData.comment_list.forEach(item=>{
          if(item.comment_id > this.lastCommentId) {
            this.lastCommentId = item.comment_id
          }
          let content = `${item.user_name}${item.content}`;
          let length = content.replace(/[\u4e00-\u9fa5]/gi, '**').length + 1;

          let height = 32;
          let num = 28;

          height = height + Math.floor(length / num) *20;
          item.height = height;
        });
        let newCommentInfoList = [...commentInfoList, ...commentData.comment_list].sort((a,b)=>{
          return a.comment_id - b.comment_id;
        });
        this.setState({
          commentInfoList: newCommentInfoList,
        },()=>{
          if(this.listRef) {
            this.listRef.resetAfterIndex((newCommentInfoList.length - commentData.comment_list.length), false);
            this.scrollCommentBottom();
          }
        });
      }

      // 处理公告
      if(commentData.notice) {
        let notice = this.state.notice;
        if(commentData.notice.is_del === 1) {
          notice = '';
        } else if(!!commentData.notice.content) {
          notice = commentData.notice.content;
        }
        if(this.state.notice !== notice) {
          this.setState({
            notice: notice,
          })
        }
      }

      // 处理直播状态
      if(commentData.live_status !== liveStatus) {
        this.setState({
          liveStatus: commentData.live_status
        })
      }

    }
  };

  scrollCommentBottom = ()=>{
    setTimeout(()=>{
      this.listRef && this.listRef.scrollToItem(this.state.commentInfoList.length, 'end');
    });
  };


  isSendingMsg = false;  // 标记正在发送消息
  sendMsg = (event:any)=>{
    if(event && event.keyCode === 13) {
      const content = event.currentTarget.value;
      if(!this.isSendingMsg && content) {
        let { activityDetail} = this.state;

        this.isSendingMsg = true;
        PostService.addComment(activityDetail.id, content).then(()=>{
          this.isSendingMsg = false;
          this.getCommentList();
        });
        this.setState({content: ''}); // 设置值为空字符串
      }
    }
  };

  inputOnFocus = (e:any) => {
    setTimeout(function(){
      if(e.target && e.target.scrollIntoView) {
        e.target.scrollIntoView(true);
      }
      // true:元素的顶端将和其所在滚动区的可视区域的顶端对齐; false:底端对齐。
    },200);  // 延时 == 键盘弹起需要时间
  };

  blur = (e:any) => {
    setTimeout(function(){
      window.scrollTo(0, 0);
    },200);  // 延时 == 键盘弹起需要时间
  };

  handleChange = (e:any)=> {
    this.setState({content: e.target.value});
  };

  close = ()=> {
    this.setState({close: !this.state.close}, ()=>{
      this.scrollCommentBottom();
    });
  };

  glistRef = (ref:any)=>{
    this.listRef = ref;
  };

  editNotice = ()=>{
    let checkLoginInfo = this.state.checkLoginInfo;
    let notice = this.state.notice;
    if(checkLoginInfo.login && checkLoginInfo.role_info.perms.length > 0 ) {
      prompt(
        '修改公告',
        '',
        [
          { text: '取消' },
          { text: '提交', onPress: content => {
            if(!content) content = '';
              PostService.addLiveNotice(this.state.activityId, content).then((id)=>{
                this.setState({
                  notice: content
                })
              });
          }},
        ],
        'default', notice)
    }
  };

  reload = ()=>{
    window.location.reload();
  };

  getNotLivingContent = (isEnd=false)=>{
    let { loading, activityDetail, basicInfo, teacherList} = this.state;

    let title = '直播还没有开始，等一会儿再来吧';
    let reload = (
      <>
        <div className="reload" onClick={this.reload}>刷新</div>
      </>);
    if(isEnd) {
      title = '直播教学已结束';
    }
    let time = '';
    if(activityDetail.start_time) {
      let mom = moment(activityDetail.start_time)
      time= mom.format('M月D日 H:mm')+'-'+ mom.add(activityDetail.duration, 'm').format('H:mm');
    }
    return (
      <>
        <div className="not-living">
          <div className="teacher-list-box">
            {teacherList.map(t=>{
              return (
                <div key={`tttt-${t.id}`}>
                  <div className="teacher-avatar " >
                    <img src={t.avatar} />
                  </div>
                  <div className="teacher-name">{t.name}</div>
                </div>
              );
            })}
          </div>

          <div className="course-name">{activityDetail.course_name}</div>
          <div className="course-name">{time}</div>

          <div className="title">{title}</div>
          {this.getChangeStatusContent()}
          {reload}
        </div>
      </>
    );
  };

  // 修改直播状态按钮
  getChangeStatusContent = () =>{
    let checkLoginInfo = this.state.checkLoginInfo;
    let videoDirection = this.state.videoDirection;
    let videoMirror = this.state.videoMirror;
    let activityDetail = this.state.activityDetail;
    let otherActivityId = this.state.otherActivityId;
    if(!otherActivityId) {
      otherActivityId = activityDetail.id;
    }
    let content = (<></>);
    if(checkLoginInfo.login && checkLoginInfo.role_info.perms.length > 0 ) {
      let comf = (status?:number)=>{
        if(status && status > 0) {
          let title = '确认设置成';
          if(status === 1) {
            title = `${title} "未开播"？`;
          } else if(status === 2) {
            title = `${title} "开播"？`;
          } else if(status === 3) {
            title = `${title} "直接结束"？`;
          }
          alert('提示', title, [
            { text: 'Cancel', onPress: () => {}, style: 'default' },
            { text: 'OK', onPress: () => {
              PostService.changeLiveStatus(this.state.activityId, status).then();
              } },
          ]);
        }
      };
      let change = ()=>{
        operation([
          { text: '未开始', onPress: () => {comf(1)}, style: {color:"#000"} },
          { text: '开播', onPress: () => {comf(2)} , style:{color:"#000"}},
          { text: '直接结束', onPress: () => {comf(3)}, style:{color:"red"}  },
        ])
      };
      let goComment = ()=>{
        this.props.history.push(`/live-comment/${this.state.activityId}`)
      };


      let changeVideoDirectionBtn = ()=>{
        operation([
          { text: `竖屏${videoDirection !==2?'(当前)':''}`, onPress: () => {this.changeVideoDirection(1).then()}, style: {color:"#000"} },
          { text: `横屏${videoDirection ===2?'(当前)':''}`, onPress: () => {this.changeVideoDirection(2).then()} , style:{color:"#000"}},
        ])
      };
      let changeMirrorBtn = ()=>{
        operation([
          { text: `镜面翻转0${videoMirror !==2?'(当前)':''}`, onPress: () => {this.changeVideoMirror(1).then()}, style: {color:"#000"} },
          { text: `镜面翻转180${videoMirror ===2?'(当前)':''}`, onPress: () => {this.changeVideoMirror(2).then()} , style:{color:"#000"}},
        ])
      };
      let changeRouteBtnList = activityDetail.activity_list.map(activity=>{
        let title = activity.start_time;
        if(activity.activity_id === activityDetail.id) {
          title = '不跳转，就在本次课';
        }
        if(otherActivityId === activity.activity_id) {
          title = `${title}【已设置】`;
        }
        return { text: title, onPress: () => {this.changeRoute(activity.activity_id).then()}, style: {color:"#000"} };
      })
      let changeRouteBtn = ()=>{
        operation(changeRouteBtnList)
      };
      let directionTitle = '竖屏';
      if(videoDirection === 2) {
        directionTitle = '横屏';
      }
      let mirrorBtn = (<></>);
      let routeBtn = (<></>);
      let directionBtn = (<></>);
      if(checkRole(checkLoginInfo.role_info.system_page_list, ['master', 'sale-manager'])) {
      // if(checkRole(checkLoginInfo.role_info.system_page_list, ['sale-manager'])) {
        mirrorBtn = <div className="change-status" onClick={changeMirrorBtn}>镜面翻转</div>;
        routeBtn = <div className="change-status" onClick={changeRouteBtn}>跳转到其他直播</div>;
        directionBtn = <div className="change-status" onClick={changeVideoDirectionBtn}>当前{directionTitle}</div>
      }

      content = (
        <>
          <div className="opt-box-box-content">
            <div className="opt-box-box">
              <div className="opt-box-title">管理员操作区</div>
              <div className="opt-box sys-opt-box">
                <div className="change-status" onClick={change}>修改直播状态</div>
                <div className="change-status" onClick={goComment}>去留言看板</div>
                {directionBtn}
                {mirrorBtn}
                {routeBtn}
              </div>
            </div>
          </div>



        </>);
    }
    return content;
  };

  // 标记这个直播是横屏还是竖屏
  // 1竖屏，2横屏
  changeVideoDirection = async (type=1)=>{
    let {activityDetail} = this.state;
    let key = getVideoDirectionKey(activityDetail.id);

    await PostService.commonRedisMark(key, `${type}`, 60 * 60 * 24, 0, false);

    this.setState({
      videoDirection: type,
    });
  }

  // 标记这个直播是镜面旋转
  // 1 0度，2 180度
  changeVideoMirror = async (type=1)=>{
    let {activityDetail} = this.state;
    let key = getVideoMirrorKey(activityDetail.id);

    await PostService.commonRedisMark(key, `${type}`, 60 * 60 * 24, 0, false);

    this.setState({
      videoMirror: type,
    });
  }

  // 标记这个直播是横屏还是竖屏
  // 1竖屏，2横屏
  getVideoDirection = async ()=>{
    let {activityDetail} = this.state;
    let key = getVideoDirectionKey(activityDetail.id);

    let mark = await PostService.commonRedisMark(key, '', 60 * 60 * 24, 1, false);
    let videoDirection = 1;
    if(mark.is_exist === 1 && !Number.isNaN(Number(mark.data))) {
      videoDirection = Number(mark.data)
    }
    this.setState({
      videoDirection: videoDirection,
    });
  }


  // 标记这个直播要跳转到其他课
  changeRoute = async (activityId:number)=>{
    let {activityDetail} = this.state;
    let key = getChangeRuteKey(activityDetail.id);
    await PostService.commonRedisMark(key, `${activityId}`, 60 * 60 * 24 * 7, 0, false);
    this.setState({
      otherActivityId: activityId,
    });
  }

  // 标记这个直播是横屏还是竖屏
  // 1竖屏，2横屏
  getRoute = async ()=>{
    let {activityDetail, checkLoginInfo} = this.state;
    let key = getChangeRuteKey(activityDetail.id);
    let mark = await PostService.commonRedisMark(key, '', 60 * 60 * 24 * 7, 1, false);
    let otherActivityId = 1;
    if(mark.is_exist === 1 && !Number.isNaN(Number(mark.data))) {
      otherActivityId = Number(mark.data);

      if(otherActivityId > 0 && otherActivityId !== activityDetail.id) {
        let activity = activityDetail.activity_list.find(a=>a.activity_id === otherActivityId);
        if(checkLoginInfo.login && checkLoginInfo.role_info.perms.length > 0 ) {
          // 管理员出提示
          if(activity && activity.activity_id) {
            alert(
              '提示',
              `即将切换到${activity.start_time}课堂`,
              [
                { text: `取消`, style: {color:"#444"} },
                { text: `确定`, onPress: () => {
                    this.props.history.push(`/live/${otherActivityId}`);
                    window.location.reload();
                  }, style: {color:"#000"} },

              ]
            );
          }
        }else {
          // 非管理员，直接跳转
          this.props.history.push(`/live/${otherActivityId}`);
          window.location.reload();
        }

      }
    }
    this.setState({
      otherActivityId: otherActivityId,
    });
  }

  // 标记这个直播的镜面旋转
  getVideoMirror = async ()=>{
    let {activityDetail} = this.state;
    let key = getVideoMirrorKey(activityDetail.id);

    let mark = await PostService.commonRedisMark(key, '', 60 * 60 * 24, 1, false);
    let videoMirror = 1;
    if(mark.is_exist === 1 && !Number.isNaN(Number(mark.data))) {
      videoMirror = Number(mark.data)
    }
    this.setState({
      videoMirror: videoMirror,
    });
  }




  // 修改直播画质按钮
  getChangeQualityContent = () =>{
    const {liveQuality, showJingzi} = this.state; // 直播状态，1 超高清，2高清
    let checkLoginInfo = this.state.checkLoginInfo;
    let content = (<></>);
    if(checkLoginInfo.login) {
      let comf = (status?:number)=>{
        if(status !== 2) {
          status = 1;
        }
        if(liveQuality !== status) {
          this.setState({
            liveQuality: status,
            reloadVideo: false,
          });
          window.localStorage.setItem(liveQualityKey, String(status));
          setTimeout(()=>{
            this.setState({
              reloadVideo: true,
            });
          })
        }
      };

      let change = ()=>{
        operation([
          { text: `超高清${liveQuality ===1?'(当前)':''}`, onPress: () => {comf(1)}, style: {color:"#000"} },
          { text: `高清${liveQuality ===2?'(当前)':''}`, onPress: () => {comf(2)} , style:{color:"#000"}},
        ])
      };
      let title = '超高清';
      if(liveQuality === 2) {
        title = '高清';
      }



      let jingzi = (<></>);
      if(this.canShowJingzi()) {
        let jn = '打开镜子';
        if(showJingzi) {
          jn = '关闭镜子';
        }
        jingzi =(<div className="change-status" onClick={()=>this.showJingzi()}>{jn}</div>);
      }
      content = (
        <>
          <div className="opt-box">
            <div className="change-status" onClick={change}>{title}</div>
            <div className="change-status" onClick={this.refresh}>刷新</div>
            {jingzi}
          </div>

        </>);
    }
    return content;
  };

  onRef = (ref:any) => {
    this.jingziChild = ref;
  };

  // 2s 发送一次
  sendFeedbackTimeKey:any = null;
  sendFeedback = (content:number)=>{
    Toast.info('反馈成功', undefined, undefined, false);
    let dom = document.getElementById(`happy-${content}`);
    if(dom) {
      dom.classList.toggle('active')
    }
    setTimeout(()=>{
      if(dom) {
        dom.classList.toggle('active')
      }
    }, 1500);

    if(!this.sendFeedbackTimeKey) {
      if(content > 0) {
        this.sendFeedbackTimeKey = setTimeout(()=>{
          PostService.sendFeedback(this.state.activityId, content).then(num=>{});
          this.sendFeedbackTimeKey = null;
        }, 2000);

      }
    }
  };

  changeShowFeedBackModel = ()=>{
    this.setState({
      showFeedBackModel: !this.state.showFeedBackModel
    })

  }
  feedBackModelOk = ()=>{
    this.changeShowFeedBackModel();
    this.setState({
      showFeedBackBtn: false,
    })
  }



  render() {
    let { loading, activityDetail, checkLoginInfo, basicInfo, commentInfoList, teacherList
      , content, close, notice, liveStatus, liveQuality, reloadVideo, showJingzi, box, showFeedbackTips,
      innerHeight, innerWidth, videoMirror, showFeedBackBtn, showFeedBackModel, showCloseTips
    } = this.state;

    if(loading) {
      return <Loading></Loading>;
    }

    if(liveStatus === 1) {
      return this.getNotLivingContent(false);
    }else if(liveStatus === 3){
      return this.getNotLivingContent(true);
    }
    let teacherAvatar = activityDetail.teacher_icon;
    let teacherName = activityDetail.teacher_name;
    if (basicInfo) {
      if(basicInfo.teacher) {
        let teacher = basicInfo.teacher.find(t=>t.teacher_id === activityDetail.teacher_id);
        if(!!teacher) {
          teacherAvatar = teacher.teacher_avatar || teacher.user_avatar;
          teacherName = teacher.name;
        }
      }
    }
    let player = (<></>);
    let flvUrl = activityDetail.live_play_url_2;
    let m3u8Url = activityDetail.live_play_url;

    if(m3u8Url && flvUrl && reloadVideo) {
      let qualityName = '';
      if(liveQuality === 2) {
        qualityName = q_720;
      }
      flvUrl = flvUrl.replace(/\.m3u8/gi, `${qualityName}.flv`);
      m3u8Url = m3u8Url.replace(/\.flv/gi, `${qualityName}.m3u8`);
      const {box} = this.state;
      player = (<>
        <VideoPlayer flvUrl={flvUrl} m3u8Url={m3u8Url} videoMirror={videoMirror} autoplay={true} controls={false} className="my-live"  poster={""} style={{width: box.videoWidth, height: box.videoHeight}} />
      </>);
    }

    let userContentList:any[] = [];
    if(activityDetail.players) {
      let userList = activityDetail.players;
      if(activityDetail.players.length > 10) {
        userList = activityDetail.players.slice(0, 9)
      }
      userContentList = userList.map(user=>{
        return (
          <div className="user avatar " key={`user-${user.user_id}`}>
            <img src={user.icon} alt={user.name} />
          </div>);
      })
    }

    const commentListStyle = {
      height: innerHeight*0.25,
      width: 200,
    };
    if(close) {
      commentListStyle.height = 60;
      commentTimeLength = 5000;
    } else {
      commentTimeLength = 2000;
    }

    const getItemSize = (index:number) => {
      let comment = commentInfoList[index];
      if(comment){
        return comment.height;
      }
      return 0;
    };

    const Row = (data:any) => {
      if(commentInfoList) {
        let comment = commentInfoList[data.index];
        if(comment){
          return (
            <div key={`comment-${comment.comment_id}`} style={data.style}>
              <div className="comment"  >
                <span className="name">{comment.user_name}</span>
                <span className="text">{comment.content}</span>
              </div>
            </div>
          )
        }
      }
      return (<></>)
    };

    let commentListBox = (<></>);
    if(commentInfoList && commentInfoList.length > 0) {
      commentListBox = (
        <>
          <VariableSizeList
            ref={this.glistRef}
            height={commentListStyle.height}
            itemCount={commentInfoList.length}
            itemSize={getItemSize}
            width={commentListStyle.width}
            estimatedItemSize={20}
          >
            {Row}
          </VariableSizeList>
        </>);
    }
    let noticeContent = notice;
    // if(!noticeContent && checkLoginInfo.login && checkLoginInfo.role_info.perms.length > 0) {
    if(!noticeContent) {
      // noticeContent = '还未设置公告，点击可编辑';
      let teacherName = ''
      if(teacherList && teacherList.length > 0) {
        teacherName = `${teacherList.map(t=>t.name).join(',')}老师的`
      }
      noticeContent = `欢迎大家来到${teacherName}线上课堂`;
    }
    let noticeContentBox = (<></>);
    if (noticeContent) {
      noticeContentBox = (
        <>
          <div className="notice" onClick={this.editNotice}>
            {noticeContent}
          </div>
        </>);
    }
    let jingziContent = (<></>);
    if(showJingzi) {
      jingziContent = (<div style={{width:box.jingziWidth, height: box.jingziHeight}}><Jingzi onRef={this.onRef} /></div>);
    }

    let feedbackTips = (<></>);
    if(showFeedbackTips) {
      feedbackTips = (<div className="feedback-tips">点击下方的表情，可以快速给老师反馈哟</div>);
    }
    let closeTips = (<></>);
    if(showCloseTips) {
      closeTips = (<div className="close-tips">点击小太阳，可以清屏哟</div>);
    }

    let teacherContent = teacherList.map(teacher=>{
      return (
        <div className="teacher" key={`tstttt_${teacher.id}`}>
          <div className="avatar">
            <img src={teacher.avatar} />
          </div>
          <span className="teacher-name">{teacher.name}</span>
        </div>
      );

    });
    let feedBackBtn = (<></>);
    if(showFeedBackBtn) {
      feedBackBtn = (<div className="feedback-btn-box show" onClick={this.changeShowFeedBackModel}><div className='feedback-btn' /><div className="feedback-text">线上课堂感觉怎么样呢？</div></div>);
    }

    let feedBackModel = (<></>);
    // if(showFeedBackModel) {
    //   feedBackModel = (<Feedback activityId={activityDetail.id} onOk={this.feedBackModelOk} onClose={this.changeShowFeedBackModel} />);
    // }
    let courseTitle = <>
      <div className="course-title">
        {activityDetail.course_name}
      </div>
    </>;
    // let zhejieLogo = <></>;
    let className = '';

    if(activityDetail.mark_type === 3) {
      className = 'zhejie-live';
      // 这街
      courseTitle = <>
        <div className="course-title zhejie-title"></div>
      </>;
      // zhejieLogo = <><div className="zhejie-logo"></div></>;
    }


    return (
      <div className={`home-live ${className} ${close?'closing':''}`}>
        <ScrollToTopOnMount/>
        <div>
          {player}
          <div className="top-opt-box">
            {teacherContent}
            {/*<div className="teacher">*/}
            {/*  <div className="avatar">*/}
            {/*    <img src={teacherAvatar} alt={teacherName}/>*/}
            {/*  </div>*/}
            {/*  <span className="teacher-name">{teacherName}</span>*/}
            {/*</div>*/}
            {courseTitle}
            {/*<div className="user-box">*/}
              {/*{userContentList}*/}
              {/*<div className="member-info" >{activityDetail.players.length}</div>*/}
            {/*</div>*/}
            {/*{zhejieLogo}*/}
            <div className="close" onClick={this.close}/>
            {closeTips}
          </div>

          <div className="comment-box">
            <div className="comment-input">
              {feedbackTips}

              <div className="input-box">
                <input type="search" onBlur={this.blur} value={content} onChange={this.handleChange}
                       placeholder="与老师交流..."
                       onKeyUp={this.sendMsg} onKeyPress={this.sendMsg}/>
              </div>
              <div className="feedback-box">
                <div className={`feedback happy`}
                     id="happy-1"
                     onClick={()=>this.sendFeedback(1)}
                />
                <div className={`feedback sad `}
                     id="happy-2"
                     onClick={()=>this.sendFeedback(2)} />
              </div>
            </div>
            <div className="comment-list">
              {commentListBox}
            </div>
            {noticeContentBox}
            {this.getChangeStatusContent()}
            {this.getChangeQualityContent()}
            {feedBackBtn}
          </div>
        </div>
        <div style={{zIndex: 30}}>
          {jingziContent}
        </div>

        {feedBackModel}

      </div>
    );
  }
}
