import React from 'react';
import './index.scss';
import {ScrollToTopOnMount} from "../../components/top";
import {RouteComponentProps} from "react-router";
import * as UtilsService from "../../service/utils";
import * as PostService from "../../service/post";
import {Button, Toast, Modal, Progress} from 'antd-mobile';
import {BasicInfo, OssConfig} from "../../service/model";
import Back from "../../components/back/index";
import {checkLogin, getActivityDetail} from "../../service/post";

const alert = Modal.alert;

const OSS = require('ali-oss');

interface State {
  config: OssConfig,
  fileUping: boolean, // 正在上传
  completeNum: number, // 上传进度，百分比
  showPage: boolean,
  title: string,
  activityTitle: string,
  subActivityTitle: string,
  size: string, // 当前上传文件的大小
  basicInfo: BasicInfo,

}

interface Props extends RouteComponentProps {
}

/**
 * 给小程序上传视频文件使用
 */
export class LoginIndex extends React.PureComponent<Props, State> {


  async componentWillMount(): Promise<void> {
    Toast.loading('加载中...', 0);
    await PostService.checkLogin(true);
    Toast.hide();
  }


  render() {

    return (
      <div className="login-index">
        <ScrollToTopOnMount/>
        <Back />

        <p>G-STEPS</p>
        <p>Keep On Dancing</p>

      </div>
    );
  }
}
