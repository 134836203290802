import React from 'react';
import './home.scss';
import {RouteComponentProps} from "react-router";
import Draggable from 'react-draggable';
import { Modal, } from 'antd-mobile';

const alert = Modal.alert;
const operation = Modal.operation;
interface State {
  streamUrl: any,
  localStream: any,
  activeDrags: number,
  scaleNum: number,
  showNotice: boolean,
}

interface Props {
  onRef?: any,
}

export class Jingzi extends React.PureComponent<Props, State> {
  localVideo: any = null;

  constructor(p: any) {
    super(p);
  }

  state = {
    streamUrl: '',
    localStream: '',
    activeDrags: 0,
    scaleNum: 1,
    showNotice: true,
  };

  componentWillMount(): void {
    this.getVideoList();
    this.setState({
      showNotice: true,
    });
    setTimeout(()=>{
      this.setState({
        showNotice: false,
      })
    }, 5*1000)
    if(this.props.onRef) {
      this.props.onRef(this);
    }
  }

  // 记住打开视频流对象
  testStream: any;

  // 获取摄像头的数量
  getVideoList() {
    if(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then((mediaDevices:any)=> {
        let list:any[] = [];
        let count = 1;

        mediaDevices.forEach((mediaDevice: { kind: string; deviceId: string;label:string; }) => {
          if (mediaDevice.kind === 'videoinput') {
            // console.log(mediaDevice);
            list.push({
              deviceId: mediaDevice.deviceId,
              label: mediaDevice.label || `摄像头${count++}`,
            })
          }
        });

        if(list && list.length > 0) {
          if(list.length === 1) {
            // 只有一个摄像头
            this.getUserMedia('').then();
          }else {
            //  多个进行选择
            operation(
              list.map(u =>{
                return { text: `${u.label}`, onPress: () => {this.getUserMedia(u.deviceId).then()}, style: {color:"#000"} }})
            );
            // alert(
            //   '提示',
            //   <p style={{width: '160px'}}>检查到有多个摄像头，请选择</p>,
            //   list.map(u =>{
            //     return { text: `${u.label}`, onPress: () => {this.getUserMedia(u.deviceId).then()}, style: {color:"#000", fontSize: '12px'} }})
            // );
          }
        }

      });
    } else {
      this.getUserMedia('').then();
    }
  }

  // 打开摄像头
  getUserMedia(deviceId: string) {
    return new Promise((resolve, reject) => {

      // 老的浏览器可能根本没有实现 mediaDevices，所以我们可以先设置一个空的对象
      if (navigator.mediaDevices === undefined) {
        // @ts-ignore
        navigator.mediaDevices = {};
      }

      // 一些浏览器部分支持 mediaDevices。我们不能直接给对象设置 getUserMedia
      // 因为这样可能会覆盖已有的属性。这里我们只会在没有getUserMedia属性的时候添加它。
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {

          // 首先，如果有getUserMedia的话，就获得它
          // @ts-ignore
          let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          // 一些浏览器根本没实现它 - 那么就返回一个error到promise的reject来保持一个统一的接口
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }

          // 否则，为老的navigator.getUserMedia方法包裹一个Promise
          return new Promise(function(resolve, reject) {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        }
      }

      const userScreenWidth = window.innerWidth || 1280;
      const userScreenHeight = window.innerHeight || 720;
      let videoConstraints:any = {
        width: userScreenWidth,
        height: userScreenHeight,
        // latency: {exact: 0.003},
        frameRate: { min:10, ideal: 20, max: 30 },

      };
      if(deviceId) {
        //  强制使用指定摄像头
        videoConstraints = {
          ...videoConstraints,
          deviceId: { exact: deviceId }
        }
      } else {
        videoConstraints = {
          ...videoConstraints,
          facingMode: "user",
        }
      }
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video: videoConstraints
      })
        .then((stream)=> {
          // console.log(
          // stream.getVideoTracks()[0].getSettings(),
          // )
          this.testStream = stream;
          // 旧的浏览器可能没有srcObject
          if ("srcObject" in this.localVideo) {
            this.localVideo.srcObject = stream;
          } else {
            // 防止在新的浏览器里使用它，应为它已经不再支持了
            this.localVideo.src = window.URL.createObjectURL(stream);
          }
          // this.localVideo.onloadedmetadata = function() {
          //   this.localVideo.play();
          // };
          resolve()
        })
        .catch(function(err) {
          console.log(err.name + ": " + err.message);
          resolve()
        });


      //@ts-ignore
      // navigator.getUserMedia = navigator.getUserMedia =
      //   navigator.getUserMedia ||
      //   // @ts-ignore
      //   navigator.webkitGetUserMedia ||
      //   // @ts-ignore
      //   navigator.mozGetUserMedia;
      // // 取用户屏最大宽度和高度
      // const userScreenWidth = window.innerWidth || 1280;
      // const userScreenHeight = window.innerHeight || 720;
      // // 配置用户摄像头视频流
      // const op = {
      //   video: {width: userScreenWidth, height: userScreenHeight},
      //   audio: false,
      // };
      // console.log(navigator.getUserMedia)
      // navigator.getUserMedia(
      //   op,
      //   stream => {
      //     // this.setState({ streamUrl: stream, localStream: stream });
      //     this.localVideo.srcObject = stream;
      //     this.testStream = stream;
      //     resolve();
      //   },
      //   () => {
      //   }
      // );
    });
  }

  // 关闭摄像头
  closeUserMedia = () => {
    if(this.testStream && this.testStream.getTracks && this.testStream.getTracks()) {
      this.testStream.getTracks()[0].stop();
    }
  };

  // 改变视频大小
  handleResizeVideo = (type: string) => {
    if (type === 'large') {
      this.setState({
        scaleNum: this.state.scaleNum + 0.1
      })
    } else if (type === 'small') {
      this.setState({
        scaleNum: this.state.scaleNum - 0.1
      })
    }
  };

  // 拖拽视频
  onStart = () => {
    this.setState({
      activeDrags: ++this.state.activeDrags
    });
  };

  // 停止拖拽视频
  onStop = () => {
    this.setState({
      activeDrags: --this.state.activeDrags
    });
  };

  render() {
    const dragHandlers = {
      onStart: this.onStart,
      onStop: this.onStop
    };
    const {showNotice} = this.state;
    let noticeContent = (<></>);
    if(showNotice) {
      noticeContent = (<><div className="jingzi-notice">视频仅用于页面展示, 数据不会上传</div></>);
    }
    return (
      <div className="mirror-wrap">
        {noticeContent}
        <div className="video-scale-wrap" style={{'transform': `scale(${this.state.scaleNum})`}}>
          <Draggable {...dragHandlers}>
            <div>
              <div className="video-scale-Y">
                <video
                  autoPlay
                  id='localVideo'
                  muted
                  ref={video => (this.localVideo = video)}
                />
              </div>
            </div>

          </Draggable>
        </div>

        <div className="resize-btns">
          <span className="btn" onClick={() => this.handleResizeVideo('large')}>+</span>
          <span className="btn" onClick={() => this.handleResizeVideo('small')}>-</span>
        </div>
      </div>
    );
  }
}
