import React from 'react';
import {BrowserRouter, Route, RouteComponentProps, Switch} from "react-router-dom";
import {Home} from "./page/home/home";
import {MiniFileUp} from "./page/mini-file-up/mini-file-up";
import {Year6Video} from "./page/year6-video/home";
import {Live} from "./page/live/home";
import {LiveComment} from "./page/live-comment/home";
import {Jingzi} from "./page/jingzi/home";
import {CourseList} from "./page/course-list/index";
import {CourseDetail} from "./page/course-detail/index";
import {XiaoNiu} from "./page/xiaoniu/index";

import './App.scss';
import './my-theme.less';
import {MiniLink} from "./page/mini-link/home";
import {LoginIndex} from "./page/login";

interface State {
  isMiniProgram: boolean,
}
class App extends React.PureComponent<any, State> {

  constructor(pr: any) {
    super(pr);
    // this.state = {}
  }

  componentWillMount(): void {

  }


  render() {

    return (
      <BrowserRouter basename='/web/'>
        <div className="App">
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/home" component={Home}/>
            <Route exact path="/login" component={LoginIndex}/>
            <Route exact path="/xiaoniu" component={XiaoNiu}/>
            <Route exact path="/jingzi" component={Jingzi}/>
            <Route exact path="/mini-file-up" component={MiniFileUp}/>
            <Route exact path="/year6-video" component={Year6Video}/>
            <Route exact path="/live/:id" component={Live}/>
            <Route exact path="/live-comment/:id" component={LiveComment}/>
            <Route exact path="/course-list" component={CourseList}/>
            <Route exact path="/course-detail/:id" component={CourseDetail}/>
            <Route exact path="/l/:key" component={MiniLink}/>
            {/*<Route exact path="*" component={Home}/>*/}

          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
