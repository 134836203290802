import React from 'react';
import './home.scss';
import {RouteComponentProps} from "react-router";
import * as PostService from "../../service/post";

interface State {
}
interface Props extends RouteComponentProps {
}
// const logoImg = require('../../static/img/logo.jpg');
// const topImg = require('../../static/img/top.jpeg');
// const storeListImg = require('../../static/img/store-list.jpeg');


const keyMap = {
  // 小程序主页
  i: {
    key: 'home',
    weixin: 'weixin://dl/business/?t=16TXQVZ1kCl',
    path: 'pages/index/index',
    query: '',
    miniType: 1, // 约课小程序
  },
  // 跳转课表
  a: {
    key: 'activityList',
    weixin: 'weixin://dl/business/?t=16aWeMhQdwc',
    path: 'pages/course/course-list/index',
    query: '',
    miniType: 1, // 约课小程序
  },
  // 跳转老师课表
  t:  {
    key: 'teacherActivityList',
    weixin: 'weixin://dl/business/?t=J54F0BZWJ8v',
    path: 'sub/teacher/course/index',
    query: '',
    miniType: 1, // 约课小程序
  },
  // 老师主页
  th:  {
    key: 'teacherHome',
    weixin: 'weixin://dl/business/?t=idaTv1Uzjnm',
    path: 'sub/teacher/home/index',
    query: '',
    miniType: 1, // 约课小程序
  },
  // 跳转同学约课
  s:  {
    key: 'signin',
    weixin: 'weixin://dl/business/?t=PEEF47JLaYc',
    path: 'pages/my-page/all-course-list/index',
    query: '',
    miniType: 1, // 约课小程序
  },
  // 跳转同学等位
  w: {
    key: 'wait',
    weixin: 'weixin://dl/business/?t=AdjQ3Rzm4dr',
    path: 'pages/my-page/all-course-list/index',
    query: 'view=wait',
    miniType: 1, // 约课小程序
  },
  // 我的订单
  o: {
    key: 'order',
    weixin: 'https://wxaurl.cn/SjH9bTlY7Is',
    path: 'pages/shop/order/list/index',
    query: '',
    miniType: 1, // 约课小程序
  },
  // 涨价通知落地页
  u: {
    key: 'upPrice',
    weixin: 'https://wxaurl.cn/us3mSTzzJcd',
    path: 'sub-2/promotion/price/index',
    query: 'm=msg', // 编辑短信来的
    miniType: 1, // 约课小程序
  },
  // 民生银行兑换码页面
  cmbc: {
    key: 'cmbcCode',
    weixin: '',
    path: 'sub-2/voucher/verify/index',
    query: 'id=12', // 编辑短信来的
    miniType: 1, // 约课小程序
  },
  // 联系我们
  c: {
    key: 'contacts',
    weixin: '',
    path: 'pages/my-page/contacts/index',
    query: '', // 编辑短信来的
    miniType: 1, // 约课小程序
  },

}


export class MiniLink extends React.PureComponent<Props, State> {

  state = {
    data : keyMap.i,
    link: '',
  }

  constructor(p: any) {
    super(p);
    const key = p.match.params.key;
    // @ts-ignore
    if(key && keyMap[key]) {
      // @ts-ignore
      let data = keyMap[key];
      if(data) {
        this.state = {
          data: data,
          link: '',
        }

        let p = {
          path: data.path, // 'pages/shop/order/list/index',
          query: data.query, // '1',
          expire_interval: 30,
          is_expire: 1,
          mini_type: 1,
        }
        PostService.createMiniUrl(p).then(url=>{
          if(url) {
            this.setState({
              link: url,
            })
          }
        })
      }
    }
  }

  componentWillMount(): void {

  }


  render() {
    const {data, link} = this.state;
    let url = data.weixin;
    if(link) {
      url = link;
    }

    return (
      <div className="wx-home-box">
        <div className="wx-home">
          <div className="content">
            <img className="logo" src={require('../../static/img/link/logo.png')} />
            {/*<p>*/}
            {/*  {data.key}*/}
            {/*</p>*/}
            <div className="link">
              <a href={url}>打开小程序</a>
            </div>
          </div>

        </div>
      </div>

    );
  }
}
