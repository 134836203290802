import React from 'react';
import './home.scss';
import {ScrollToTopOnMount} from "../../components/top";
import {RouteComponentProps} from "react-router";
import {Info} from "../../components/info/info";
import Back from "../../components/back/index";
import Footer from '../../components/footer/footer';
interface State {
}
interface Props extends RouteComponentProps {
}
const logoImg = require('../../static/img/logo.jpg');
const topImg = require('../../static/img/top.jpeg');
const storeListImg = require('../../static/img/store-list.jpeg');

export class Home extends React.PureComponent<Props, State> {

  constructor(p: any) {
    super(p);

    this.state = {
    }
  }

  componentWillMount(): void {

  }
  render() {
    return (
      <div className="home">
        <ScrollToTopOnMount/>
        <Back />
        {/*<img className="logo" src={logoImg} alt="G-STEPS" />*/}
        <img className="logo" src={topImg} alt="G-STEPS" />
        {/*<p className="desc">*/}
        {/*  G-STEPS 街舞工作室致力于让更多普通人爱上街舞，目前在北京八家门店，立足于 Hip-Hop 文化发展，提供适合普通人的教学课堂，重视基本功，重视 Old School 课程，兼容 Urban，Mv Dance 等，期望与更多舞者合作，期望更多普通人开始跳舞*/}
        {/*</p>*/}
        <Info />
        <Footer color={'black'} />
      </div>
    );
  }
}
