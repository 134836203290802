import React from 'react';
import './info.scss';
import { WhiteSpace, Card, } from "antd-mobile";
import * as PostService from "../../service/post";
import {StudioGuide} from "../../service/model";
import moment from "moment";

interface State {
  showQa: boolean,
  showTeacher: boolean,
  showIntro: boolean,
  showAddress: boolean,
  showPhoto: boolean,
  studioList: Array<StudioGuide>,
  now:string,
}
export class Info extends React.PureComponent<any, State> {

  state = {
    showQa: false,
    showTeacher: false,
    showIntro: false,
    showAddress: false,
    showPhoto: false,
    studioList: [],
    now: '',
  };
  componentDidMount() {
    PostService.getStudioList().then(list=> {
      this.setState({
        studioList: list.filter(s=>{
          return s.visible_range === 0 && s.studio_type === 1 && s.enterprise_id <= 0;
        }),
      })
    });

    PostService.getNowTime().then(time=>{
      this.setState({
        now: time
      })
    })
  }

  showMore = (type:string)=>()=>{
    if(type === 'qa') {
      this.setState({
        showQa: true,
      })
    }
    else if(type === 'teacher') {
      this.setState({
        showTeacher: true,
      })
    }
    else if(type === 'address') {
      this.setState({
        showAddress: true,
      })
    }
    else if(type === 'intro') {
      this.setState({
        showIntro: true,
      })
    }
    else if(type === 'photo') {
      this.setState({
        showPhoto: true,
      })
    }
  };
  render() {

    const {showQa, showTeacher, showAddress, showIntro, showPhoto, studioList, now} = this.state;
    let studioContent = (<></>);
    if(studioList && studioList.length > 0) {
      let list = studioList.map((studio: StudioGuide)=>{
        let logo = studio.logo_url;
        if(!logo) {
          logo = require("../../static/img/logo-2.jpeg");
        }
        // let phone = '17611650778';
        let phone = '010-60644941';
        return (<div key={studio.id}>
            <div className="flex-list">
              <div className="flex-left">
                <img src={logo} alt=""/>
              </div>
              <div className="flex-right">
                <p className="title">{studio.name}</p>
                <p className="">{studio.address}</p>
                {/*<p className="">{studio.contact_phone}</p>*/}
                <p className="">{phone}</p>
              </div>
            </div>
          <WhiteSpace size="lg"/>
        </div>);
      });
      studioContent = (<>{list}</>)
    }

    let yearImg = require("../../static/img/year/8.jpg");
    let year = 8;
    let nowData = moment();
    if(now && moment(now).isValid()) {
      nowData = moment(now);
    }
    if((nowData.year() - 2013) >= 8) {
      year = (nowData.year() - 2013);
    }
    if(year === 9) {
      yearImg = require("../../static/img/year/9.jpg");
    } else if(year === 10) {
      yearImg = require("../../static/img/year/10.jpg");
    }else if(year === 11) {
      yearImg = require("../../static/img/year/11.jpg");
    }else if(year === 12) {
      yearImg = require("../../static/img/year/12.jpg");
    }else if(year === 13) {
      yearImg = require("../../static/img/year/13.jpg");
    }else if(year === 14) {
      yearImg = require("../../static/img/year/14.jpg");
    }else if(year === 15) {
      yearImg = require("../../static/img/year/15.jpg");
    }else if(year === 16) {
      yearImg = require("../../static/img/year/16.jpg");
    }


    const studioLength = studioList.length > 0 ? studioList.length : 24 ;
    return (
      <>
        <p className="desc">
          G-STEPS 街舞工作室致力于让更多普通人爱上街舞，目前在北京{studioLength}家门店，立足于 Hip-Hop 文化发展，提供适合普通人的教学课堂，重视基本功，重视 Old School 课程，兼容 Urban，Mv Dance 等，期望与更多舞者合作，期望更多普通人开始跳舞
        </p>
        <div className="info-comp">
          <WhiteSpace size="xs"/>
          <Card full>
            <Card.Body className="card-content">
              <div>
                <div className="card-title">
                  <img src={require("../../static/img/title/jieshao.png")} alt="G社介绍"/>
                </div>
                <WhiteSpace size="lg"/>
                <p>G-STEPS 街舞工作室，目前在北京有{studioLength}家实体门店（包含合作门店），以上班族零基础成人街舞培训为主，以4-16岁少儿街舞培训为辅。</p>
                <div className="flex-list">
                  <div className="flex-left">
                    {/*<img src={require("../../static/img/photo/6year.png")} alt=""/>*/}
                    <img src={yearImg} alt=""/>
                  </div>
                  <div className="flex-right">
                    <p className="title">{year} 年</p>
                    <p className="">G社最早由一群热爱Hip-Hop的上班族发起，创立 {year} 年有余</p>
                  </div>
                </div>

                <WhiteSpace size="lg"/>
                <div className="flex-list">
                  <div className="flex-left">
                    <img src={require("../../static/img/photo/item2.png")} alt=""/>
                  </div>
                  <div className="flex-right">
                    <p className="title">专注零基础 四肢不协调</p>
                    <p className="">独特的零基础小班教学，帮助你慢慢起步</p>
                  </div>
                </div>

                <WhiteSpace size="lg"/>
                <div className="flex-list">
                  <div className="flex-left">
                    <img src={require("../../static/img/photo/item3.png")} alt=""/>
                  </div>
                  <div className="flex-right">
                    <p className="title">10000 人次</p>
                    <p className="">G社创立至今，合作十余家上市公司，目前培训上班族超过 10000 人</p>
                  </div>
                </div>

                <WhiteSpace size="lg"/>
                <div className="flex-list">
                  <div className="flex-left">
                    <img src={require("../../static/img/photo/item4.png")} alt=""/>
                  </div>
                  <div className="flex-right">
                    <p className="title">{studioLength}家门店</p>
                    <p className="">在北京各大商区、办公区已经开设{studioLength}家门店；还有与【RMB舞蹈工作室】【SPY舞蹈中心】【PureSoul舞蹈工作室】合作门店，会员卡通用</p>
                  </div>
                </div>

                <p>联系我们：010-60644941</p>

                {/*{introContent}*/}

              </div>
            </Card.Body>
          </Card>

          <WhiteSpace size="xs"/>
          {
            (studioList && studioList.length && studioList.length>0) ?
                <>
                  <Card full>
                    <Card.Body className="card-content">
                      <div>
                        <div className="card-title">
                          <img src={require("../../static/img/title/zhiyingdizhi.png")} alt="直营店地址"/>
                        </div>

                        {studioContent}

                        {/*<div className="flex-list">*/}
                        {/*  <div className="flex-left">*/}
                        {/*    <img src={require("../../static/img/photo/hengtong.jpg")} alt=""/>*/}
                        {/*  </div>*/}
                        {/*  <div className="flex-right">*/}
                        {/*    <p className="title">恒通旗舰店</p>*/}
                        {/*    <p className="">朝阳区酒仙桥北路恒通国际创新园C15</p>*/}
                        {/*    <p className="">13683301593</p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*<WhiteSpace size="lg"/>*/}
                        {/*<div className="flex-list">*/}
                        {/*  <div className="flex-left">*/}
                        {/*    <img src={require("../../static/img/photo/soho.jpg")} alt=""/>*/}
                        {/*  </div>*/}
                        {/*  <div className="flex-right">*/}
                        {/*    <p className="title">望京SOHO店</p>*/}
                        {/*    <p className="">朝阳区望京SOHO商业区B1-1015（下沉广场）</p>*/}
                        {/*    <p className="">18311262558</p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*<WhiteSpace size="lg"/>*/}
                        {/*<div className="flex-list">*/}
                        {/*  <div className="flex-left">*/}
                        {/*    <img src={require("../../static/img/photo/xinzhongguan.jpg")} alt=""/>*/}
                        {/*  </div>*/}
                        {/*  <div className="flex-right">*/}
                        {/*    <p className="title">新中关店</p>*/}
                        {/*    <p className="">海淀区新中关购物中心B座南翼608室</p>*/}
                        {/*    <p className="">13552097365</p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*<WhiteSpace size="lg"/>*/}
                        {/*<div className="flex-list">*/}
                        {/*  <div className="flex-left">*/}
                        {/*    <img src={require("../../static/img/photo/xiangyun.jpg")} alt=""/>*/}
                        {/*  </div>*/}
                        {/*  <div className="flex-right">*/}
                        {/*    <p className="title">祥云小镇店</p>*/}
                        {/*    <p className="">顺义区安泰大街九号院中粮祥云小镇8号楼606室</p>*/}
                        {/*    <p className="">15201210820</p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*<WhiteSpace size="lg"/>*/}
                        {/*<div className="flex-list">*/}
                        {/*  <div className="flex-left">*/}
                        {/*    <img src={require("../../static/img/photo/changyingtianjie.jpg")} alt=""/>*/}
                        {/*  </div>*/}
                        {/*  <div className="flex-right">*/}
                        {/*    <p className="title">长楹天街店</p>*/}
                        {/*    <p className="">朝阳区龙湖长楹天街商业街西区D-108</p>*/}
                        {/*    <p className="">17611346866</p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*<WhiteSpace size="lg"/>*/}
                        {/*<div className="flex-list">*/}
                        {/*  <div className="flex-left">*/}
                        {/*    <img src={require("../../static/img/photo/wudaokou.jpg")} alt=""/>*/}
                        {/*  </div>*/}
                        {/*  <div className="flex-right">*/}
                        {/*    <p className="title">五道口店</p>*/}
                        {/*    <p className="">海淀区成府路28号五道口购物中心优盛大厦D座 10层1006室</p>*/}
                        {/*    <p className="">13552266949</p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*<WhiteSpace size="lg"/>*/}
                        {/*<div className="flex-list">*/}
                        {/*  <div className="flex-left">*/}
                        {/*    <img src={require("../../static/img/photo/guomao.jpeg")} alt=""/>*/}
                        {/*  </div>*/}
                        {/*  <div className="flex-right">*/}
                        {/*    <p className="title">国贸店</p>*/}
                        {/*    <p className="">朝阳区建外SOHO东区A座1903</p>*/}
                        {/*    <p className="">17801306268</p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*<WhiteSpace size="lg"/>*/}
                        {/*<div className="flex-list">*/}
                        {/*  <div className="flex-left">*/}
                        {/*    <img src={require("../../static/img/photo/langang.jpeg")} alt=""/>*/}
                        {/*  </div>*/}
                        {/*  <div className="flex-right">*/}
                        {/*    <p className="title">蓝港方寸店</p>*/}
                        {/*    <p className="">朝阳区朝阳公园路6号 SOLANA蓝色港湾1层湖畔美食街A122号</p>*/}
                        {/*    <p className="">17812262964</p>*/}
                        {/*  </div>*/}
                        {/*</div>*/}

                        {/*<WhiteSpace size="lg"/>*/}
                      </div>
                    </Card.Body>
                  </Card>
                  <WhiteSpace size="xs"/>
                </>
                :null
          }


          <Card full>
            <Card.Body className="card-content">
              <div className="qa">
                <div className="card-title">
                  <img src={require("../../static/img/title/qa.png")} alt="常见问题"/>
                </div>

                <p style={{fontWeight:700}}>Q：零基础学员担心自己跟不上怎么办？</p>
                <p>A：除常规课外，针对新手零基础学员，每月另外开设3-6期不同舞种的新手小班课程。精品教学，专门学习一个舞种的基本功，帮助零基础、四肢不协调的新手起步，降低后续常规课的学习门槛。</p>

                <WhiteSpace size="lg"/>
                <p style={{fontWeight:700}}>Q：老板跑路怎么办？</p>
                <p>A：G社成立于2013年，是北京第一家专业服务于上班族的舞蹈培训机构。截止目前北京各大商区已经开设{studioLength}家门店，会员卡可在所有门店通用。</p>

                <WhiteSpace size="lg"/>
                <p style={{fontWeight:700}}>Q：老师水平怎么样？</p>
                <p>A：帝都知名舞者，都拥有多年舞龄教龄，经验丰富。老师人都超Nice！定期会请国际知名大师级舞者来开workshop，帮助学员做针对性提高。</p>
                <WhiteSpace size="lg"/>
                {/*{qaContent}*/}
              </div>
            </Card.Body>
          </Card>

          <WhiteSpace size="xs"/>
          <Card full>
            <Card.Body className="card-content">
              <div>
                <div className="card-title">
                  <img src={require("../../static/img/title/kajiage.png")} alt="标准次卡价格"/>
                </div>
                <div style={{maxWidth:"600px"}}>
                  {/*<img src={require("../../static/img/title/new-price.jpeg")} />*/}
                  {/*<img src={require("../../static/img/title/price-3.jpeg")} />*/}
                  {/*<img src={require("../../static/img/title/price2022.jpeg")} />*/}
                  <img src={require("../../static/img/title/price2023.jpg")} />
                </div>
              </div>
            </Card.Body>
          </Card>
          <WhiteSpace size="xs"/>
          <Card full>
            <Card.Body className="card-content">
              <div>
                <div className="card-title">
                  <p>微信小程序预约体验课</p>
                </div>
                <div className="go-mini">
                  <a href="weixin://dl/business/?t=vhITOW37t2g">打开G-STEPS小程序</a>
                </div>
                <div style={{maxWidth:"600px"}}>
                  <img src={require("../../static/img/miniqr.png")} />
                </div>
              </div>
            </Card.Body>
          </Card>
          {/*<WhiteSpace size="xs"/>*/}
          {/*<Card full>*/}
          {/*  <Card.Body className="card-content">*/}
          {/*    <div>*/}
          {/*      <div className="card-title">*/}
          {/*        <p>微信小程序预约体验课</p>*/}
          {/*      </div>*/}
          {/*      <div style={{maxWidth:"600px"}}>*/}
          {/*        <p>*/}
          {/*          联系我们：010-89193916   17611650778*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </Card.Body>*/}
          {/*</Card>*/}

          {/*<WhiteSpace size="xs"/>*/}
          {/*<Card full>*/}
          {/*  <Card.Body className="card-content">*/}
          {/*    <div>*/}
          {/*      <div className="card-title" >*/}
          {/*        <img src={require("../../static/img/title/zhixun.png")} alt="欢迎咨询"/>*/}
          {/*      </div>*/}
          {/*      <div style={{textAlign:"center"}}>*/}
          {/*        <img style={{width:"70%", border:"1px solid #dcdcdc", borderRadius:"4px", maxWidth: '200px'}} src={require("../../static/img/title/qr.png")} alt="" />*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </Card.Body>*/}
          {/*</Card>*/}
        </div>

      </>
    );
  }
}
